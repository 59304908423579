import axios from 'axios';
import qs from 'qs';

const API_URL = process.env.REACT_APP_FABEL_API_URL
//const API_URL = "http://127.0.0.1:8000/"

//service for all authentication purposes
class AuthService {
    login(username, password) {
        const data = {
            grant_type: 'password',
            username: username,
            password: password,
            client_id: process.env.REACT_APP_FC_CLIENT_ID,
            client_secret: process.env.REACT_APP_FC_CLIENT_SECRET,
        }

        
        const options = {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: qs.stringify(data),
            url: API_URL + 'v2/token/',
        }    
           
        return axios(options)
            .then(response => {
                //console.log("response", response);
                if (response.data.access_token) {
                    let today = new Date()
                    let currentLocalTime = today.toLocaleTimeString();
                    today.setMinutes(today.getMinutes() + 45);
                    let updatedLocalTime = today.toLocaleTimeString();
                    today.setMinutes(today.getMinutes() + 15);
                    let maxTokenExpireTime = today.toLocaleTimeString();


                    //today.setHours(today.getHours() + 1)
                    const responseWithExpiry = {...response.data, expiration: updatedLocalTime, maxtime: maxTokenExpireTime}
                    window.sessionStorage.setItem('fabel_connect_token', JSON.stringify(responseWithExpiry))
                    //window.sessionStorage.setItem('fabel_connect_token', JSON.stringify(responseWithExpiry), {expires: 7}, {sameSite: 'Lax'})
                    
                }
                return response.data;
            })
    }

    logout() {
        window.sessionStorage.removeItem('SpesialtilbudShow') ;
        window.sessionStorage.removeItem('fabel_connect_token', { path: '/' })
        
    }
    
    refreshAccessToken(refreshToken) {
       const data = {
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            client_id: process.env.REACT_APP_FC_CLIENT_ID,
            client_secret: process.env.REACT_APP_FC_CLIENT_SECRET,
        }

        
        const options = {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: qs.stringify(data),
            url: API_URL + 'v2/token/',
        }    
           
        return axios(options)
            .then(response => {
                //console.log("response", response);
                if (response.data.access_token) {
                    let today = new Date()
                    let currentLocalTime = today.toLocaleTimeString();
                    today.setMinutes(today.getMinutes() + 45);
                    let updatedLocalTime = today.toLocaleTimeString();
                    today.setMinutes(today.getMinutes() + 15);
                    let maxTokenExpireTime = today.toLocaleTimeString();


                    //today.setHours(today.getHours() + 1)
                    const responseWithExpiry = {...response.data, expiration: updatedLocalTime, maxtime: maxTokenExpireTime}
                    window.sessionStorage.setItem('fabel_connect_token', JSON.stringify(responseWithExpiry))
                    //window.sessionStorage.setItem('fabel_connect_token', JSON.stringify(responseWithExpiry), {expires: 7}, {sameSite: 'Lax'})
                    
                }
                return response.data;
            })
    }


    getCurrentToken() {
        if (window.sessionStorage.getItem('fabel_connect_token')) {
            const currentTime = new Date()
            const accessTokenExpiration = new Date(JSON.parse(window.sessionStorage.getItem('fabel_connect_token')).expiration)
            if (currentTime > accessTokenExpiration) {
                //console.log("refresh me please")
                this.logout();
            }
            return JSON.parse(window.sessionStorage.getItem('fabel_connect_token'))
        }
        else {
            return false
        }
    }

    

    getEncryptedCredentials(user, pass) {
        const data = {
            username: user,
            password: pass
        }
        
        const options = {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: qs.stringify(data),
            url: process.env.REACT_APP_FABEL_WP_URL + 'SSO/encryptcred.php',
        }
        
        
        return axios(options)
            .then(response => {
                
                return response.data;
            })
    }
    //Not used
    getEncrypted() {
        if (window.sessionStorage.getItem('fabel_connect_token')) {
            const currentTime = new Date()
            const accessTokenExpiration = new Date(JSON.parse(window.sessionStorage.getItem('fabel_connect_token')).expiration)
            if (currentTime > accessTokenExpiration) {
                //console.log("refresh me please")
            }
            return JSON.parse(window.sessionStorage.getItem('fabel_connect_token'))
        }
        else {
            return false
        }
    }

}


export default new AuthService();