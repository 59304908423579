import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router';
import { Card, Row, Col, Button, Container, Image, Form } from 'react-bootstrap'
import UserService from '../services/UserService';
import AuthService from '../services/AuthService';


const Books = () => {
    const location = useLocation();
    const [booksRendered, setBooksRendered] = useState(location.state.books)
    
    const downloadBook = (link) => {
        const currentToken = AuthService.getCurrentToken();
        UserService.downloadBook(currentToken.access_token, link)
        .then(response => {
            window.location.href = response.download_url
        }, (e) => {
            console.log(e)
        })
    }
    let booksCopy = location.state.books
    const search = e => {
        let searchString = e.currentTarget.value.toLowerCase()
        let searchResult = []
        booksCopy.forEach((book) => {
            if (book.title.toLowerCase().includes(searchString)) {
                searchResult.push(book)
            } else if (book.author.split(',').reverse().join(' ').toLowerCase().includes(searchString)) {
                searchResult.push(book)
            }
        })
        setBooksRendered(searchResult)
    }
    const history = useHistory();
    const backToMyPage = () => {   
        history.push('/min-side')
    }
    return (
<>
        
    <Card className="purchased-books-controller">
        <Row>
            <Col><Button variant="link" onClick={backToMyPage}>tilbake til min side</Button></Col>
            <Col><Row><Form.Control type="text" placeholder="Søk etter bok..." onChange={search}/></Row></Col>
            <Col></Col>
            </Row>
        </Card>
        <Card className = "card-main">
            <Card.Header><div className="bold-text">Mine bøker</div></Card.Header>
            {booksRendered.map(book => (
                <Card className="book-card">
                    <Container>
                    <Row className="align-items-center">
                        <Col><Image src={book.image ? book.image : "https://fabel.no/wp-content/uploads/2021/12/New-default-cover.jpg" } className="book-thumbnail" thumbnail></Image></Col>
                        <Col className="book-text align-items-center"><div className="bold-text">{book.title}</div><br/>{book.author ? book.author : ""}</Col>
                        <Col className="btn-download-book-col"><Button className="btn-download-book btn-fabel float-right" onClick={()=> downloadBook(book.download)}>Last ned</Button></Col>
                    </Row>
                    </Container>
                </Card>
            ))}
        </Card>
  </>
    )
}

export default Books
