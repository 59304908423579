import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";

const RenewToken = () => {
	let history = useHistory();
	  useEffect(() => {
	   const currentToken = AuthService.getCurrentToken();
	   AuthService.refreshAccessToken(currentToken.refresh_token).then(
	   	(response) => {
	   		console.log(response)
	   		history.push("/min-side");
	        //window.location.reload();
	   	},
	   	(error) => {
	   		console.log(error)
	   		history.push("/logg-inn");
	   	})
	  }, []);

  return (
    <>
      
    </>
  );
};

export default RenewToken;
