import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
import RenewToken from './components/RenewToken';
import LoginCheck from './components/LoginCheck';
import MyPage from './components/MyPage';
import Books from './components/Books';
import React, { useState, useEffect } from 'react'
import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter, Route} from "react-router-dom";
import ForgottenPassword from './components/ForgottenPassword';
import ResetPassword from './components/ResetPassword';
import TerminateSubscripton from './components/TerminateSubscripton';
import YesToNewsletter from './components/YesToNewsletter';
import VippsSuccess from './components/VippsSuccess';
import Receipt from './components/Receipt';
import Spesialtilbud from './components/Spesialtilbud';




function App() {
  const [topBanner, setTopBanner] = useState(false);
  return (
    <div className="App">
      <Header topBanner={topBanner} />
      <BrowserRouter>
      <div className="view-container">
        <Route exact path={"/"} component={LoginCheck} />
        <Route exact path={"/logg-inn"} component={() => <Login/>}/>
        <Route exact path={"/renew-token"} component={() => <RenewToken/>}/>
        <Route exact path={"/min-side"}><MyPage setTopBanner={setTopBanner}/></Route>
        <Route exact path={"/min-side/boker"} component={Books} />
        <Route exact path={"/sms-kode"} component={ForgottenPassword} />
        <Route exact path={"/tilbakestille-passord"} component={ResetPassword} />
        <Route exact path={"/avslutt-abonnement"} component={TerminateSubscripton} />
        <Route exact path={"/ja-til-nyhetsbrev"} component={YesToNewsletter} />
        <Route exact path={"/kvittering"} component={Receipt} />
        <Route exact path={"/vipps-completed"} component={VippsSuccess} />
         <Route exact path={"/spesialtilbud"} component={Spesialtilbud} />
      </div>
      </BrowserRouter>
      <Footer/>
    </div>
  );
}
export default App;
