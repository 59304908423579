import { useState, useEffect } from "react";
import { useHistory } from 'react-router';
function Spesialtilbud() {
   const history = useHistory();
   useEffect(() => {
    window.sessionStorage.setItem('SpesialtilbudShow', 'true');
    //history.push("/tilbakestille-passord");
    history.push("/min-side");
  }, []);
  
  return (
  <div>
   
  </div>
    )
}
export default Spesialtilbud;