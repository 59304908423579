import {useEffect, useState} from 'react';
import { useHistory } from 'react-router';
import { Redirect } from "react-router-dom";

import "../styles/vippsuccess.css";
//import { useSearchParams } from "react-router-dom";
//import PaymentService from "../services/PaymentService";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import vippslogo from "../images/vipps-logo.jpg";
import loadingImage from "../images/loading.gif";
function VippsSuccess() {
  //const navigate = useNavigate();
  const [redirect, setRedirect] = useState(null);
  const [vippsMessage, setVippsMessage] = useState(null)
  const [vippsLoading, setVippsLoading] = useState(false)
  const [userReady, setUserReady] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    username: "",
  });
  const queryParameters = new URLSearchParams(window.location.search)
  const productId = queryParameters.get("product_id")
  const history = useHistory();
 // const searchParams = useSearchParams();
  //const user_id = searchParams.get("user_id")
  //const productId = searchParams.get("product_id")
  const currentToken = AuthService.getCurrentToken();
  //const accessToken = localStorage.getItem(process.env.REACT_APP_USER_ACCESS_TOKEN);
  const vippsData = {
        "product_id": window.sessionStorage.getItem('selectedProduct'), //productId,
        "utm_campaign": "UTMCAMPAIGN",
        "reference": window.sessionStorage.getItem('vippsreference')
      }
  useEffect(() => {
    /*console.log("user_id", user_id);
    console.log("product_id", productId);
    console.log("accessToken", accessToken);
    console.log("vippsreference", localStorage.getItem("vippsreference"));*/
    const delayedFunction = () => {
      UserService.vippsSubscription(currentToken.access_token, vippsData)
      .then((vipps) => {
        UserService.getUserFromToken(currentToken.access_token).then(
        (user) => {
          UserService.userGetLog("Vipps Success Page 1", user,  JSON.stringify(currentUser));
          console.log('success getUserFromToken');
          setCurrentUser(user);
          setUserReady(true);
        
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.message) {
              
              const errorMsg = error.response.data.message;
              if(errorMsg.includes("Token not found") || errorMsg.includes("Token expired") || errorMsg.includes("Invalid Token")){
                UserService.userGetLog("error Vipps Page: "+currentToken.access_token, errorMsg, JSON.stringify(currentUser));
                AuthService.logout();
                setRedirect({ redirect: "/logg-inn" });
              }
              console.log("new error  mypage 1", error.response.data.message); // Handle error message here
            } else {
              console.log('An unexpected error occurred');
            }
          });;
       // UserService.paymentSwitchLogging(vipps, currentUser.mobile, "", productId);
        //console.log("vipps", vipps);
        if(vipps.status===200){
          setVippsMessage('Abonnementet ble byttet');
          setVippsLoading(true);
          setTimeout(() => {
            history.push('/min-side')
          }, 8000);
        } else {
          console.log("vipps", vipps);
          setVippsMessage('Noe gikk galt');
          //setVippsMessage('Noe gikk galt  - '+ vipps.status + ' - ' +vipps.data.message);
          //setVippsMessage('Noe gikk galt');
        }
        
     
      }).catch(error => {
        setVippsMessage('Noe gikk galt');
        if (error.response && error.response.data && error.response.data.message) {
          
          const errorMsg = error.response.data.message;
          if(errorMsg.includes("Token not found") || errorMsg.includes("Token expired") || errorMsg.includes("Invalid Token")){
            UserService.userGetLog("error Vipps Page 2: "+currentToken.access_token, errorMsg, JSON.stringify(currentUser));
            AuthService.logout();
            setRedirect({ redirect: "/logg-inn" });
          }
          //console.log("new error  mypage 1", error.response.data.message); // Handle error message here
        } else {
          console.log('An unexpected error occurred');
        }
      });
    }
    const delayTimeout = setTimeout(delayedFunction, 5000);
    return () => {
      clearTimeout(delayTimeout);
    };
  }, [])
  
  if (redirect) {
    return <Redirect to={redirect.redirect} />;
  }
  return (
  <div>
    <div className="txtAlignCenter">
      <br /><br />
      <img src="https://filer.fabel.no/web-images/uploads/2019/01/fabel-logo-RGB.png" className="VippsSucFabelLogo" />
      <br /><br />
      <img src={vippslogo} alt="visa-logo" className="vippSucVipLogo" />
      <br /><br />
      <div className="vippssuccessmessage">
      {vippsMessage ? vippsMessage : ''}
      <br /><br />
      {vippsLoading===true ? <img src={loadingImage} alt="Loading..."  /> : ''}
      </div>
    </div>
  </div>
    )
}
export default VippsSuccess;