const subscriptions = [
    {
        id: 1,
        name: "Fabel Basis",
        price: 189,
        priceMonths: "kr pr måneder",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        campaignExpiry: "01.03.2022",
    },
    {
        id: 101,
        name: "Standard: ",
        price: 189,
        priceMonths: "kr/mnd",
        concurrentStreams: 1,
        profiles: 1,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker.",
    },
    {
        id: 102,
        name: "Fabel Basis",
        price: 149,
        priceMonths: "kr pr måneder",
        concurrentStreams: 1,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker, bortsett fra til barn og ungdom",
    },
    {
        id: 201,
        name: "Standard: ",
        price: 189,
        priceMonths: "kr/mnd",
        concurrentStreams: 1,
        profiles: 1,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker.",
    },
    {
        id: 104,
        name: "Fabel Familie Pluss",
        slogan: "Ubegrenset tilgang til alle lydbøker og -serier",
        price: 239,
        priceMonths: "kr pr måneder",
        concurrentStreams: 4,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 103,
        name: "Fabel Familie",
        price: 199,
        priceMonths: "kr pr måneder",
        slogan: "Ubegrenset tilgang til alle lydbøker og -serier",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 109,
        name: "Fabel Familie",
        price: 199,
        priceMonths: "kr pr måneder",
        slogan: "Ubegrenset tilgang til alle lydbøker og -serier",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 654,
        name: "Fabel Familie",
        price: 199,
        priceMonths: "kr pr måneder",
        slogan: "Ubegrenset tilgang til alle lydbøker og -serier",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 655,
        name: "Fabel Familie",
        price: 199,
        priceMonths: "kr pr måneder",
        slogan: "Ubegrenset tilgang til alle lydbøker og -serier",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 202,
        name: "Fabel Basis",
        price: 159,
        priceMonths: "kr pr måneder",
        concurrentStreams: 1,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker, bortsett fra til barn og ungdom",
    },

    {
        id: 701,
        name: "Fabel Familie",
        price: 189,
        priceMonths: "kr pr måneder",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 702,
        name: "Fabel Familie",
        price: 189,
        priceMonths: "kr pr måneder",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 1501,
        name: "Fabel Familie",
        price: 199,
        priceMonths: "kr pr måneder",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 2501,
        name: "Fabel Familie",
        price: 199,
        priceMonths: "kr pr måneder",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 3501,
        name: "Fabel Familie",
        price: 199,
        priceMonths: "kr pr måneder",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 3502,
        name: "Fabel Familie",
        price: 199,
        priceMonths: "kr pr måneder",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 551,
        name: "Fabel Familie",
        price: 199,
        priceMonths: "kr pr måneder",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 651,
        name: "Fabel Familie",
        price: 199,
        priceMonths: "kr pr måneder",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 3503,
        name: "Fabel Familie",
        price: 199,
        priceMonths: "kr pr måneder",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },

    {
        id: 105,
        name: "Fabel Familie 15% rabatt",
        price: 189,
        priceMonths: "kr pr måneder",
        saleprice: 160,
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 106,
        name: "Fabel Familie 15% rabatt",
        price: 189,
        priceMonths: "kr pr måneder",
        saleprice: 160,
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 3002,
        name: "Fabel Familie 15% rabatt",
        price: 189,
        priceMonths: "kr pr måneder",
        saleprice: 160,
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 3003,
        name: "Fabel Familie 15% rabatt",
        price: 189,
        priceMonths: "kr pr måneder",
        saleprice: 160,
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },

    {
        id: 1002,
        name: "Fabel Familie 50% rabatt",
        price: 189,
        priceMonths: "kr pr måneder",
        saleprice: 95,
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 4001,
        name: "Fabel Familie 60% rabatt",
        price: 189,
        priceMonths: "kr pr måneder",
        saleprice: 75,
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 4002,
        name: "Fabel Familie 60% rabatt",
        price: 189,
        priceMonths: "kr pr måneder",
        saleprice: 75,
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 4003,
        name: "Fabel Familie 50% rabatt",
        price: 189,
        priceMonths: "kr pr måneder",
        saleprice: 95,
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 107,
        name: "Fabel Familie 15% rabatt",
        price: 189,
        priceMonths: "kr pr måneder",
        saleprice: 160,
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 110,
        name: "Fabel Familie",
        price: 189,
        priceMonths: "kr pr måneder",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },

    {
        id: 603,
        name: "Fabel Familie i 10 uker til kun 95 kroner",
        price: 189,
        priceMonths: "kr pr måneder",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },

    {
        id: 604,
        name: "Fabel Familie",
        nameLarge: "Fabel Familie",

        slogan: "Tilgang til alle lydbøker",
        text1: "Er du sikker? Å avslutte abonnementet nå vil begrense tilgangen din til underholdning og kunnskap i rolige perioder som vinterferie og påskeferie. Akkurat når du har tid til å slappe av og fordype deg i lesingen.",
        text2: "Ombestemmer du deg, byr vi i en begrenset periode på: ",
        text3: "Fabel Familie i 2 måneder for kun 99 kroner.",
        text4: "Etter to måneder fortsetter abonnementet til ordinær pris, 189 kroner per måned.",
        text5: "Du kan når som helst stoppe abonnementet.",
        price: 99,
        priceMonths: "2 måneder til 99 kroner",
        profiles: 6,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 4,
        subscriptionState: "",
        exceptionProduct: "604",
        campaignPosition: "avslutt",
        startDate: "2024-02-15",
        endDate: "2024-03-01",
    },
    {
        id: 703,
        name: "Fabel Familie til prisen for Fabel Basis i 2 måneder",
        nameLarge: "Fabel Familie til prisen for Fabel Basis i 2 måneder",
        season: "Back to school! ",
        seasonTagLine:
            "Nå kan du prøve vårt mest populære abonnement til prisen for Fabel Basis",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 149,
        priceMonths: "kr/mnd i 2 måneder, deretter ordinær pris (189 kr/mnd)",
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        concurrentStreams: 2,
        subscriptionState:
            "ACTIVE, TRIALING, RENEW_FAILURE, UNPAID, INCOMPLETE, STOPPED",
        exceptionProduct: "all",
        allowedProduct: "102, 202",
        campaignPosition: "productselector",
        startDate: "2023-08-15",
        endDate: "2023-10-01",
    },
    {
        id: 605,
        name: "Fabel Familie i to måneder til kr 99",
        slogan: "Tilgang til alle lydbøker",
        text1: "Er du helt sikker på at du ikke vil ha ubegrenset mulighet for lydboklytting?",
        text2: "Vi har forlenget sommertilbudet:",
        price: 99,
        priceMonths: "kr for 2 måneder, deretter ordinær pris (189 kr/mnd)",
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        concurrentStreams: 2,
        subscriptionState: "",
        exceptionProduct: "",
        campaignPosition: "avslutt",
        startDate: "2023-07-28",
        endDate: "2023-10-01",
    },
    {
        id: 1502,
        name: "Fabel Familie",
        season: "Tilbud",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 199,
        priceMonths: "kr pr måneder",
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        concurrentStreams: 2,
        subscriptionState: "",
        exceptionProduct: "",
        campaignPosition: "productselector",
        startDate: "2023-07-28",
        endDate: "2023-09-01",
    },

    {
        id: 502,
        name: "Fabel Familie i hele ti uker til kun 95 kroner.",
        price: 189,
        priceMonths: "kr pr måneder",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        renewButton: true,
        renewCondition: "STOPPED",

        slogan: "Tilgang til alle lydbøker",
    },
    {
        id: 11,
        name: "Fabel Familie",
        price: 189,
        priceMonths: "kr pr måneder",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        slogan: "Tilgang til alle lydbøker",
    },

    {
        id: 21,
        name: "Fabel Pluss",
        price: 199,
        priceMonths: "kr pr måneder",
        concurrentStreams: 4,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        correspond: "Fabel Familie Pluss",
        saleprice: 229,
    },
    {
        id: 31,
        name: "Fabel Premium",
        price: 239,
        priceMonths: "kr pr måneder",
        concurrentStreams: 6,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        correspond: "Fabel Familie Pluss",
        saleprice: 229,
    },
    {
        id: 44,
        name: "Nyttårstilbud",
        price: 149,
        priceMonths: "kr pr måneder",
        concurrentStreams: 1,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        campaignExpiry: "30.06.2022",
    },
    {
        id: 41,
        name: "Sommerkampanje",
        price: 139,
        priceMonths: "kr pr måneder",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        campaignExpiry: "31.12.2021",
    },
    {
        id: 42,
        name: "Høstkampanje",
        price: 139,
        priceMonths: "kr pr måneder",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        campaignExpiry: "01.03.2022",
    },
    {
        id: 410,
        name: "Sommerkampanje - forny",
        price: 139,
        priceMonths: "kr pr måneder",
        concurrentStreams: 2,
        profiles: 6,
        state: true,
        main: false,
        campaign: false,
        campaignExpiry: "01.06.2022",
    },
    {
        id: 99,
        name: "No subscription",
        price: 0,
        campaign: false,
    },
    {
        id: 606,
        name: "Kampanje: Fabel Familie 95 kr pr måned i 1 måned.",
        nameLarge: "Kampanje: Fabel Familie 95 kr pr måned i 1 måned.",
        season: "Høstkampanje! ",
        seasonTagLine: "Fabel Familie til halv pris i 2 måneder",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 95,
        priceMonths: "",
        profiles: 6,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 2,
        subscriptionState: "STOPPED",
        exceptionProduct: "1003, 607, 503",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: "true",
        startDate: "2024-03-15",
        endDate: "2024-04-02",
    },
    {
        id: 607,
        name: "Kampanje: Fabel Familie 95 kr pr måned i 1 måned.",
        nameLarge: "Kampanje: Fabel Familie 95 kr pr måned i 1 måned.",
        season: "Høstkampanje! ",
        seasonTagLine: "Fabel Familie til halv pris i 2 måneder",
        slogan: "Tilgang til alle lydbøker",
        text1: "I en begrenset periode tilbyr vi deg påskekampanjen:",
        text2: "Fabel Familie til 50 % rabatt i 1 måned.",
        text3: "95 kroner pr måned i 1 måned, deretter Fabel Familie til standard pris 189 kroner pr måned. Løpende abonnement.",
        text4: "",
        price: 95,
        priceMonths: "",
        profiles: 6,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 2,
        subscriptionState: "",
        exceptionProduct: "1003, 606, 503",
        allowedProduct: "",
        campaignPosition: "avslutt",
        spesialtilbud: "null",
        startDate: "2024-03-15",
        endDate: "2024-04-02",
    },
    {
        id: 608,
        name: "Fabel Familie i 2 måneder til prisen for Fabel Én Bok",
        nameLarge: "Fabel Familie i 2 måneder til prisen for Fabel Én Bok",
        season: "Ut året-kampanje 2023 ",
        seasonTagLine: "Fabel Familie til kun 89 kroner per mnd i 2 måneder!",
        slogan: "Tilgang til alle lydbøker",

        text1: "Er du helt sikker på at du vil forlate oss?",
        text2: "Bli, og få Fabel Familie til prisen for Fabel Én Bok i to måneder.",
        text3: "Ubegrenset lytting for hele familien i førjulstiden og juleferien.",
        text4: "Kun 89 kroner per mnd, deretter 189 kr/mnd.",
        textbelowbutton: "",
        price: 89,
        priceMonths: "kr/mnd i 2 måneder, deretter ordinær pris (189 kr/mnd)",
        profiles: 6,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 2,
        subscriptionState: "",
        exceptionProduct: "608",
        allowedProduct: "",
        campaignPosition: "avslutt",
        spesialtilbud: "null",
        startDate: "2023-11-14",
        endDate: "2023-12-01",
    },
    {
        id: 503,
        name: "Kampanje: Fabel Familie 95 kr pr måned i 1 måned.",
        nameLarge: "Kampanje: Fabel Familie 95 kr pr måned i 1 måned.",
        season: "Høstkampanje! ",
        seasonTagLine: "Fabel Familie til halv pris i 2 måneder",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 95,
        priceMonths: "",
        profiles: 6,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 2,
        subscriptionState: "DEACTIVATED",
        exceptionProduct: "all",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: "true",
        startDate: "2024-03-15",
        endDate: "2024-04-02",
    },
    {
        id: 504,
        welcomeBack: "Takk for sist og velkommen tilbake!",
        name: "Fabel Familie i 1 mnd for kun 9 kroner",
        nameLarge: "Fabel Familie i 1 mnd for kun 9 kroner",
        season: "Kom tilbake ",
        seasonTagLine: "Få Fabel Familie i 1 mnd for kun 9 kroner",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 9,
        priceMonths: "kr/mnd i 1 mnd, deretter ordinær pris (189 kr/mnd)",
        profiles: 6,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 2,
        subscriptionState: "DEACTIVATED",
        exceptionProduct: "all",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: "null",
        startDate: "2023-11-23",
        endDate: "2023-12-12",
    },
    {
        id: 609,
        welcomeBack: "Takk for sist og velkommen tilbake!",
        name: "Fabel Familie i 1 mnd til kun 9 kroner",
        nameLarge: "Fabel Familie i 1 mnd til kun 9 kroner",
        season: "Hva annet for du til 9 kroner nå for tiden? ",
        seasonTagLine: "Bli, og hør på lydbok i julestria",
        slogan: "Ubegrenset tilgang til alle lydbøker og -serier",
        text1: "",
        text2: "",
        price: 9,
        priceMonths: "kr/mnd i 1 mnd, deretter 189 kr/mnd",
        profiles: 6,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 2,
        subscriptionState: "STOPPED",
        exceptionProduct: "all",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: null,
        startDate: "2023-12-15",
        endDate: "2024-01-31",
    },
    {
        id: 1001,
        welcomeBack: "",
        name: "",
        nameLarge: "60 % rabatt på Fabel Familie i 2 måneder",
        season: "Lytt til oss",
        seasonTagLine: "60 % rabatt på Fabel Familie i 2 måneder",
        slogan: "Ubegrenset tilgang til alle lydbøker og -serier",
        text1: "",
        text2: "",
        price: 75,
        priceMonths: "kr/mnd i 2 mnd, deretter ordinær pris (189 kr/mnd)",
        profiles: 6,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 2,
        subscriptionState: "DEACTIVATED",
        exceptionProduct: "all",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: null,
        startDate: "2023-12-15",
        endDate: "2024-01-31",
    },
    {
        id: 2001,
        welcomeBack: "",
        name: "",
        nameLarge: "60 % rabatt på Fabel Familie i 2 måneder",
        season: "Lytt til oss",
        seasonTagLine: "60 % rabatt på Fabel Familie i 2 måneder",
        slogan: "Ubegrenset tilgang til alle lydbøker og -serier",
        text1: "",
        text2: "",
        price: 75,
        priceMonths: "kr/mnd i 2 mnd, deretter ordinær pris (189 kr/mnd)",
        profiles: 6,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 2,
        subscriptionState: "DEACTIVATED",
        exceptionProduct: "all",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: "true",
        startDate: "2023-12-15",
        endDate: "2024-01-22",
    },
    {
        id: 505,
        welcomeBack: "",
        name: "",
        nameLarge: "Ubegrenset lytting en hel måned for kun 9 kroner",
        season: "",
        seasonTagLine: "60 % rabatt på Fabel Familie 1 mndr",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 9,
        priceMonths: "kr/mnd i 1 mnd, deretter ordinær pris (189 kr/mnd)",
        profiles: 6,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 2,
        subscriptionState: "DEACTIVATED",
        exceptionProduct: "all",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: "null",
        startDate: "2023-12-15",
        endDate: "2023-01-30",
    },
    {
        id: 610,
        welcomeBack: "Takk for sist og velkommen tilbake!",
        name: "Fabel Familie til 60 % rabatt i 2 mnd",
        nameLarge: "Fabel Familie til 60 % rabatt i 2 mnd",
        season: "Hva annet for du til 9 kroner nå for tiden? ",
        seasonTagLine: "Bli, og hør på lydbok i julestria",
        slogan: "Ubegrenset tilgang til alle lydbøker og -serier",
        text1: "Er du helt sikker på at du vil forlate oss?",
        text2: "Bli, og få Fabel Familie i to måneder til 60 % rabatt!",
        text3: "Ubegrenset lytting for hele familien i førjulstiden og juleferien.",
        text4: "Kun 75 kroner per mnd, deretter 189 kr/mnd.",
        price: 75,
        priceMonths: "kr/mnd i 2 mnd, deretter 189 kr/mnd",
        profiles: 6,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 2,
        subscriptionState: "",
        exceptionProduct: "610",
        allowedProduct: "all",
        campaignPosition: "avslutt",
        spesialtilbud: null,
        startDate: "2023-12-15",
        endDate: "2024-01-31",
    },

    {
        id: 1003,
        name: "Kampanje: Fabel Familie 95 kr pr måned i 1 måned.",
        nameLarge: "Kampanje: Fabel Familie 95 kr pr måned i 1 måned.",
        season: "Høstkampanje! ",
        seasonTagLine: "Fabel Familie til halv pris i 2 måneder",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 95,
        priceMonths: "",
        profiles: 6,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 2,
        subscriptionState:
            "DEACTIVATED, RENEW_FAILURE, UNPAID, INCOMPLETE, STOPPED",
        exceptionProduct: "all",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: null,
        startDate: "2024-03-15",
        endDate: "2024-04-02",
    },
    {
        id: 501,
        name: "Kampanje: Standard i 10 uker for 99 kroner. ",
        nameLarge: "Kampanje: Standard i 10 uker for 99 kroner. ",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 99,
        priceMonths: "",
        profiles: 1,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 1,
        subscriptionState:
            "ACTIVE, TRIALING, DEACTIVATED, RENEW_FAILURE, UNPAID, INCOMPLETE, STOPPED",
        exceptionProduct: "601, 501",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: "true",
        startDate: "2024-05-07",
        endDate: "2024-06-10",
    },
    {
        id: 601,
        name: "Kampanje: Standard i 10 uker for 99 kroner. ",
        nameLarge: "Kampanje: Standard i 10 uker for 99 kroner. ",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 99,
        priceMonths: "",
        profiles: 1,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 1,
        subscriptionState: "STOPPED",
        exceptionProduct: "601, 501",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: null,
        startDate: "2024-05-07",
        endDate: "2024-06-10",
    },
    {
        id: 1004,
        name: "Standard 50 % rabatt i 2 mnd : ",
        nameLarge: "Standard 50 % rabatt i 2 mnd : ",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 199,
        priceMonths: "kr/mnd",
        saleprice: 99,
        profiles: 1,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 1,
        subscriptionState: "DEACTIVATED",
        exceptionProduct: "all",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: null,
        startDate: "2024-06-10",
        endDate: "2024-07-10",
    },
    {
        id: 1005,
        name: "Duo 50 % rabatt i 2 mnd : ",
        nameLarge: "Duo 50 % rabatt i 2 mnd : ",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 239,
        priceMonths: "kr/mnd",
        saleprice: 119,
        profiles: 2,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 2,
        subscriptionState: "DEACTIVATED",
        exceptionProduct: "all",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: null,
        startDate: "2024-06-10",
        endDate: "2024-07-10",
    },
    {
        id: 1006,
        name: "Familie 50 % rabatt i 2 mnd : ",
        nameLarge: "Familie 50 % rabatt i 2 mnd : ",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 269,
        priceMonths: "kr/mnd",
        saleprice: 134,
        profiles: 3,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 3,
        subscriptionState: "DEACTIVATED",
        exceptionProduct: "all",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: null,
        startDate: "2024-06-10",
        endDate: "2024-07-10",
    },
    {
        id: 506,
        name: "Standard 50 % rabatt i 2 mnd : ",
        nameLarge: "Standard 50 % rabatt i 2 mnd : ",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 199,
        priceMonths: "kr/mnd",
        saleprice: 99,
        profiles: 1,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 1,
        subscriptionState: "DEACTIVATED",
        exceptionProduct: "all",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: "true",
        startDate: "2024-06-10",
        endDate: "2024-07-10",
    },
    {
        id: 507,
        name: "Duo 50 % rabatt i 2 mnd : ",
        nameLarge: "Duo 50 % rabatt i 2 mnd : ",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 239,
        priceMonths: "kr/mnd",
        saleprice: 119,
        profiles: 2,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 2,
        subscriptionState: "DEACTIVATED",
        exceptionProduct: "all",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: "true",
        startDate: "2024-06-10",
        endDate: "2024-07-10",
    },
    {
        id: 508,
        name: "Familie 50 % rabatt i 2 mnd : ",
        nameLarge: "Familie 50 % rabatt i 2 mnd : ",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 269,
        priceMonths: "kr/mnd",
        saleprice: 134,
        profiles: 3,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 3,
        subscriptionState: "DEACTIVATED",
        exceptionProduct: "all",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: "true",
        startDate: "2024-06-10",
        endDate: "2024-07-10",
    },
    {
        id: 614,
        name: "Standard 50 % rabatt i 2 mnd : ",
        nameLarge: "Standard 50 % rabatt i 2 mnd : ",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 199,
        priceMonths: "kr/mnd",
        saleprice: 99,
        profiles: 1,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 1,
        subscriptionState: "STOPPED",
        exceptionProduct: "614",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: null,
        startDate: "2024-06-10",
        endDate: "2024-07-10",
    },
    {
        id: 615,
        name: "Duo 50 % rabatt i 2 mnd : ",
        nameLarge: "Duo 50 % rabatt i 2 mnd : ",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 239,
        priceMonths: "kr/mnd",
        saleprice: 119,
        profiles: 2,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 2,
        subscriptionState: "STOPPED",
        exceptionProduct: "615",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: null,
        startDate: "2024-06-10",
        endDate: "2024-07-10",
    },
    {
        id: 616,
        name: "Familie 50 % rabatt i 2 mnd : ",
        nameLarge: "Familie 50 % rabatt i 2 mnd : ",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker",
        text1: "",
        text2: "",
        price: 269,
        priceMonths: "kr/mnd",
        saleprice: 134,
        profiles: 3,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 3,
        subscriptionState: "STOPPED",
        exceptionProduct: "616",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: null,
        startDate: "2024-06-10",
        endDate: "2024-07-10",
    },

    {
        id: 611,
        name: "Standard 50 % rabatt i 2 mnd : ",
        nameLarge: "Standard 50 % rabatt i 2 mnd : ",
        plainName: "Standard 50 % rabatt i 2 mnd",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker",
        text1: "Er du helt sikker på at du vil forlate oss?",
        text2: "Bli, og få Fabel Familie i to måneder til 60 % rabatt!",
        text3: "Ubegrenset lytting for hele familien i førjulstiden og juleferien.",
        text4: "Kun 75 kroner per mnd, deretter 189 kr/mnd.",
        price: 199,
        priceMonths: "kr/mnd",
        saleprice: 99,
        profiles: 1,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 1,
        subscriptionState: "TRIALING, ACTIVE",
        exceptionProduct: "611",
        allowedProduct: "all",
        campaignPosition: "avslutt",
        spesialtilbud: null,
        startDate: "2024-06-10",
        endDate: "2024-07-10",
    },
    {
        id: 612,
        name: "Duo 50 % rabatt i 2 mnd : ",
        nameLarge: "Duo 50 % rabatt i 2 mnd : ",
        plainName: "Duo 50 % rabatt i 2 mnd",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker",
        text1: "Er du helt sikker på at du vil forlate oss?",
        text2: "Bli, og få Fabel Familie i to måneder til 60 % rabatt!",
        text3: "Ubegrenset lytting for hele familien i førjulstiden og juleferien.",
        text4: "Kun 75 kroner per mnd, deretter 189 kr/mnd.",
        price: 239,
        priceMonths: "kr/mnd",
        saleprice: 119,
        profiles: 2,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 2,
        subscriptionState: "TRIALING, ACTIVE",
        exceptionProduct: "612",
        allowedProduct: "all",
        campaignPosition: "avslutt",
        spesialtilbud: null,
        startDate: "2024-06-10",
        endDate: "2024-07-10",
    },
    {
        id: 613,
        name: "Familie 50 % rabatt i 2 mnd : ",
        nameLarge: "Familie 50 % rabatt i 2 mnd : ",
        plainName: "Familie 50 % rabatt i 2 mnd",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker",
        text1: "Er du helt sikker på at du vil forlate oss?",
        text2: "Bli, og få Fabel Familie i to måneder til 60 % rabatt!",
        text3: "Ubegrenset lytting for hele familien i førjulstiden og juleferien.",
        text4: "Kun 75 kroner per mnd, deretter 189 kr/mnd.",
        price: 269,
        priceMonths: "kr/mnd",
        saleprice: 134,
        profiles: 3,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 3,
        subscriptionState: "TRIALING, ACTIVE",
        exceptionProduct: "613",
        allowedProduct: "all",
        campaignPosition: "avslutt",
        spesialtilbud: null,
        startDate: "2024-06-10",
        endDate: "2024-07-10",
    },
    {
        id: 617,
        name: "Standard 50 % rabatt i 2 mnd : ",
        nameLarge: "Standard 50 % rabatt i 2 mnd : ",
        plainName: "Standard 50 % rabatt i 2 mnd",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker.",
        text1: "Er du helt sikker på at du vil stoppe abonnementet?",
        text2: "Akkurat tilbyr vi muligheten til å forlenge lyttesommeren med to måneder til halv pris, kr 99 per mnd. Til glede for hele familien! ",
        text3: "",
        text4: "",
        price: 99,
        priceMonths: "kr/mnd",
        profiles: 1,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 3,
        subscriptionState: "TRIALING, ACTIVE",
        exceptionProduct: "617",
        allowedProduct: "all",
        campaignPosition: "avslutt",
        spesialtilbud: null,
        startDate: "2024-07-10",
        endDate: "2024-08-19",
    },
    {
        id: 618,
        name: "Standard 50 % rabatt i 2 mnd : ",
        nameLarge: "Standard 50 % rabatt i 2 mnd : ",
        season: " ",
        seasonTagLine:
            "Akkurat nå tilbyr vi å forlenge lyttesommeren med halv pris på Standard i 2 mnd. Til glede for hele familien! ",
        slogan: "Tilgang til alle lydbøker.",
        text1: "",
        text2: "",
        price: 99,
        priceMonths: "kr/mnd",
        profiles: 1,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 3,
        subscriptionState: "DEACTIVATED, STOPPED",
        exceptionProduct: "618",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: null,
        startDate: "2024-07-10",
        endDate: "2024-08-19",
    },
    {
        id: 509,
        name: "Standard 50 % rabatt i 2 mnd : ",
        nameLarge: "Standard 50 % rabatt i 2 mnd : ",
        season: " ",
        seasonTagLine:
            "Akkurat nå tilbyr vi å forlenge lyttesommeren med halv pris på Standard i 2 mnd. Til glede for hele familien! ",
        slogan: "Tilgang til alle lydbøker.",
        text1: "",
        text2: "",
        price: 99,
        priceMonths: "kr/mnd",
        profiles: 1,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 3,
        subscriptionState:
            "ACTIVE, TRIALING, DEACTIVATED, RENEW_FAILURE, UNPAID, INCOMPLETE, STOPPED",
        exceptionProduct: "all",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: null,
        startDate: "2024-07-10",
        endDate: "2024-08-19",
    },
    {
        id: 510,
        name: "Standard 50 % rabatt i 2 mnd : ",
        nameLarge: "Standard til kampanjepris, 99 kr/mnd i 2 mnd. ",
        season: " ",
        seasonTagLine: "Høsttilbud: 50 % rabatt på Standard i 2 måneder.",
        seasonTagLineTwo:
            "Lytt så mye du vil for kun 99 kr/mnd. Etter 2 mnd gjelder vanlig pris, 199 kr/mnd.",
        slogan: "Tilgang til alle lydbøker.",
        text1: "",
        text2: "",
        price: 99,
        priceMonths: "kr/mnd",
        profiles: 1,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 3,
        subscriptionState:
            "ACTIVE, TRIALING, DEACTIVATED, RENEW_FAILURE, UNPAID, INCOMPLETE, STOPPED",
        exceptionProduct: "all",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: null,
        startDate: "2024-09-23",
        endDate: "2024-10-06",
    },
    {
        id: 619,
        name: "Standard 50 % rabatt i 2 mnd : ",
        nameLarge: "Standard 50 % rabatt i 2 mnd : ",
        plainName: "Standard 50 % rabatt i 2 mnd",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker.",
        text1: "Bli hos oss - slå til på høsttilbudet:",
        text2: "50 % rabatt på Standard i 2 måneder ",
        text3: "Lytt så mye du vil for kun 99 kroner per mnd. ",
        text4: "Etter to måneder gjelder vanlig pris, 199 kr/ mnd",
        price: 99,
        priceMonths: "kr/mnd",
        profiles: 1,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 3,
        subscriptionState: "TRIALING, ACTIVE",
        exceptionProduct: "619",
        allowedProduct: "all",
        campaignPosition: "avslutt",
        spesialtilbud: null,
        startDate: "2024-10-07",
        endDate: "2024-10-21",
    },
    {
        id: 512,
        name: "Standard til 9 kr i 1 mnd (kampanje) ",
        nameLarge: "Standard til 9 kr i 1 mnd (kampanje) ",
        season: " ",
        seasonTagLine: "Oktoberfest! ",
        seasonTagLineTwo:
            "Kom tilbake og få Fabel Standard til kun 9 kr i 1 måned",
        seasonTagLineThree: "Etter 1 måned gjelder vanlig pris, 199 kr/mnd.",
        seasonTagLineFour: "Etter 2 måneder gjelder vanlig pris, 269 kr/mnd. ",
        slogan: "Tilgang til alle lydbøker.",
        text1: "",
        text2: "",
        price: 95,
        priceMonths: "kr/mnd",
        profiles: 1,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 3,
        subscriptionState:
            "ACTIVE, TRIALING, RENEW_FAILURE, UNPAID, STOPPED, DEACTIVATED, INCOMPLETE_EXPIRED, INCOMPLETE",
        exceptionProduct: "all",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: null,
        startDate: "2024-10-10",
        endDate: "2024-11-20",
    },
    {
        id: 621,
        name: "Standard til kampanjepris, 9 kr/mnd i 1 mnd ",
        nameLarge: "Standard til kampanjepris, 9 kr/mnd i 1 mnd ",
        season: " ",
        seasonTagLine: "Novembertilbud: Standard til kun 9 kroner for 1 måned!",
        seasonTagLineTwo:
            "Lytt så mye du vil. Etter 1 måned gjelder vanlig pris, 199 kr/mnd.",
        seasonTagLineThree: "",
        seasonTagLineFour: "",
        slogan: "Tilgang til alle lydbøker.",
        text1: "",
        text2: "",
        price: 95,
        priceMonths: "kr/mnd",
        profiles: 1,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 3,
        subscriptionState: "STOPPED, DEACTIVATED",
        exceptionProduct: "all",
        allowedProduct: "all",
        campaignPosition: "productselector",
        spesialtilbud: null,
        startDate: "2024-11-04",
        endDate: "2024-11-18",
    },
    {
        id: 622,
        name: "Standard: 9 kr/mnd i 1 mnd (kampanje)",
        nameLarge: "Standard: 9 kr/mnd i 1 mnd (kampanje)",
        plainName: "Standard: 9 kr/mnd i 1 mnd (kampanje)",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker.",
        text1: "Førjulstilbud! Vi ønsker å beholde deg og tilbyr:",
        text2: "1 måned med Fabel Standard for KUN 9 kr ",
        text3: "",
        text4: "Etter to måneder gjelder vanlig pris, 199 kr/ mnd.",
        price: 9,
        priceMonths: "kr/mnd",
        profiles: 1,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 3,
        subscriptionState: "TRIALING, ACTIVE",
        exceptionProduct: "622",
        allowedProduct: "all",
        campaignPosition: "avslutt",
        spesialtilbud: null,
        startDate: "2024-11-01",
        endDate: "2024-12-02",
    },
    {
        id: 403,
        name: "Familie: ",
        slogan: "Tilgang til alle lydbøker.",
        price: 269,
        priceMonths: "kr/mnd",
        concurrentStreams: 3,
        profiles: 3,
        state: true,
        main: false,
        campaign: false,
        subscriptionState: "TRIALING, ACTIVE",
        exceptionProduct: "all",
        allowedProduct: "all",
        startDate: "2024-12-01",
        endDate: "2030-12-31",
    },
    {
        id: 402,
        name: "Duo: ",
        price: 239,
        priceMonths: "kr/mnd",
        slogan: "Tilgang til alle lydbøker.",
        concurrentStreams: 2,
        profiles: 2,
        state: true,
        main: false,
        expirycheck: true,
        campaign: false,
        subscriptionState: "TRIALING, ACTIVE",
        exceptionProduct: "all",
        allowedProduct: "all",
        startDate: "2024-12-01",
        endDate: "2030-12-31",
    },

    {
        id: 401,
        name: "Standard: ",
        price: 199,
        priceMonths: "kr/mnd",
        concurrentStreams: 1,
        profiles: 1,
        state: true,
        main: false,
        campaign: false,
        subscriptionState: "TRIALING, ACTIVE",
        exceptionProduct: "all",
        allowedProduct: "all",
        slogan: "Tilgang til alle lydbøker.",
        startDate: "2024-12-01",
        endDate: "2030-12-31",
    },
    {
        id: 406,
        name: "Familie: ",
        slogan: "Tilgang til alle lydbøker.",
        price: 269,
        priceMonths: "kr/mnd",
        concurrentStreams: 3,
        profiles: 3,
        state: true,
        main: true,
        campaign: false,
        subscriptionState:
            "ACTIVE, TRIALING, RENEW_FAILURE, UNPAID, STOPPED, DEACTIVATED, INCOMPLETE_EXPIRED, INCOMPLETE",
        exceptionProduct: "all",
        allowedProduct: "all",
        startDate: "2024-12-01",
        endDate: "2030-12-31",
    },
    {
        id: 405,
        name: "Duo: ",
        price: 239,
        priceMonths: "kr/mnd",
        slogan: "Tilgang til alle lydbøker.",
        concurrentStreams: 2,
        profiles: 2,
        state: true,
        main: true,
        expirycheck: true,
        campaign: false,
        subscriptionState:
            "ACTIVE, TRIALING, RENEW_FAILURE, UNPAID, STOPPED, DEACTIVATED, INCOMPLETE_EXPIRED, INCOMPLETE",
        exceptionProduct: "all",
        allowedProduct: "all",
        startDate: "2024-12-01",
        endDate: "2030-12-31",
    },
    {
        id: 404,
        name: "Standard: ",
        price: 199,
        priceMonths: "kr/mnd",
        concurrentStreams: 1,
        profiles: 1,
        state: true,
        main: true,
        campaign: false,
        subscriptionState:
            "ACTIVE, TRIALING, RENEW_FAILURE, UNPAID, STOPPED, DEACTIVATED, INCOMPLETE_EXPIRED, INCOMPLETE",
        exceptionProduct: "all",
        allowedProduct: "all",
        slogan: "Tilgang til alle lydbøker.",
        startDate: "2024-12-01",
        endDate: "2030-12-31",
    },
    {
        id: 515,
        name: "Familie: ",
        selectedName: "Familie: 108 kr/mnd i 2 mnd (kampanje)",
        slogan: "Tilgang til alle lydbøker.",
        price: 269,
        priceMonths: "kr/mnd",
        saleprice: 108,
        salepriceTag: "i 2 mnd (60 %)",
        concurrentStreams: 3,
        profiles: 3,
        state: true,
        main: true,
        campaign: true,
        subscriptionState: "DEACTIVATED",
        exceptionProduct: "all",
        allowedProduct: "all",
        startDate: "2024-12-01",
        endDate: "2025-01-12",
    },
    {
        id: 514,
        name: "Duo: ",
        selectedName: "Duo: 96 kr/mnd i 2 mnd (kampanje)",
        price: 239,
        priceMonths: "kr/mnd",
        saleprice: 96,
        salepriceTag: "i 2 mnd (60 %)",
        slogan: "Tilgang til alle lydbøker.",
        concurrentStreams: 2,
        profiles: 2,
        state: true,
        main: true,
        campaign: true,
        subscriptionState: "DEACTIVATED",
        exceptionProduct: "all",
        allowedProduct: "all",
        startDate: "2024-12-01",
        endDate: "2025-01-12",
    },
    {
        id: 513,
        name: "Standard: ",
        selectedName: "Standard: 80 kr/mnd i 2 mnd (kampanje)",
        price: 269,
        priceMonths: "kr/mnd",
        saleprice: 80,
        salepriceTag: "i 2 mnd (60 %)",
        concurrentStreams: 1,
        profiles: 1,
        state: true,
        main: true,
        campaign: true,
        subscriptionState: "DEACTIVATED",
        exceptionProduct: "all",
        allowedProduct: "all",
        slogan: "Tilgang til alle lydbøker.",
        startDate: "2024-12-01",
        endDate: "2025-01-12",
    },

    {
        id: 623,
        name: "Standard: 9 kr/mnd i 1 mnd (kampanje)",
        nameLarge: "Standard: 9 kr/mnd i 1 mnd (kampanje)",
        plainName: "Standard: 9 kr/mnd i 1 mnd (kampanje)",
        season: " ",
        seasonTagLine: "",
        slogan: "Tilgang til alle lydbøker.",
        text1: "Vi vil gjerne beholde deg:",
        text2: "Få Standard til kun 9 kr i 1 måned.",
        text3: "Lytt ubegrenset.",
        text4: "Etter 1 mnd gjelder vanlig pris.",
        price: 9,
        priceMonths: "kr/mnd",
        profiles: 1,
        state: true,
        main: false,
        campaign: true,
        concurrentStreams: 3,
        subscriptionState: "TRIALING, ACTIVE",
        exceptionProduct: "623",
        allowedProduct: "all",
        campaignPosition: "avslutt",
        spesialtilbud: null,
        startDate: "2024-12-16",
        endDate: "2025-01-16",
    },
];

export default subscriptions;
