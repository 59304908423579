import React, { useState, useEffect } from "react";
import AuthService from "../services/AuthService";
import  { Redirect } from 'react-router-dom'

const LoginCheck = () => {
  const currentToken = AuthService.getCurrentToken();

  if (currentToken) {
    return <Redirect to='/min-side'/>
  }else{
    return <Redirect to='/logg-inn'/>
  }
};

export default LoginCheck;
