import axios from "axios";
import qs from "qs";

const API_URL = process.env.REACT_APP_FABEL_API_URL
//const API_URL = "http://127.0.0.1:8000/"

class ClientService {
    getAccessToken() {
        const data = {
            grant_type: 'client_credentials',
            client_id: process.env.REACT_APP_FC_CLIENT_ID,
            client_secret: process.env.REACT_APP_FC_CLIENT_SECRET
        }
        const options = {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: qs.stringify(data),
            url: API_URL + 'v2/token/'
        }
        return axios(options)
            .then(response => {
                return response.data.access_token
            })
    }

    sendSMS(access_token, msisdn) {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({mobile: msisdn}),
            url: API_URL + 'v2/user/reset-password'
        }
        return axios(options)
            .then(response => {
                return response.data;
            });
    }

    changePassword(access_token, password, code, msisdn) {
        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({password: password, code: code, mobile: msisdn}),
            url: API_URL + 'v2/registeruser/'       
        }
        return axios(options)
        .then(response => {
            return response.data;
        });
    }

}


export default new ClientService()