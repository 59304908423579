import React, { useState, useEffect } from "react";
import { Card, ListGroup, Row, Col, Button, Modal } from "react-bootstrap";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import subscriptions from "../../services/SubscriptionClass";
import Radio from "@mui/material/Radio";
import Cookies from "universal-cookie";
import { formatDateString } from "./utils";
const SubscriptionCard = ({
  setTopBanner,
  title,
  currentUser,
  setCurrentUser,
  subscription,
  setSubscription,
}) => {
  const [expiryDate, setExpiryDate] = useState("");
  const [newSubscriptionID, setNewSubscriptionID] = useState("");
  const [popupChangeSub, setPopupChangeSub] = useState(false);
  const [popupChangeSubKvitering, setPopupChangeSubKvitering] = useState(false);
  const [chosenProd, setChosenProd] = useState("");
  const [popupForny, setPopupForny] = useState(false);
  const [popupRenewSub, setPopupRenewSub] = useState(false);
  const [popupFornyKvitering, setPopupFornyKvitering] = useState(false);
  const [productID, setProductID] = useState("");
  const [productExpiry, setProductExpiry] = useState("");
  const [campaign, setCampaign] = useState(false);
  const [campaignPrice, setCampaignPrice] = useState("");
  const [campaignExpiry, setCampaignExpiry] = useState("");
  const [subState, setSubState] = useState();
  const [disableMode, setDisableMode] = useState(false);
  const [changeSubState, setChangeSubState] = useState(false);
  const [subname, setSubName] = useState("");
  const [oneBookCredits, setOneBookCredits] = useState("");
  const [selectedID, setSelectedID] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popMsg, setPopUpMsg] = useState("");
  const currentDate = new Date(); //.getDate();

  let campaignStartDate = "";
  let campaignEndDate = "";
  let campaignExceptionProduct = "";
  let campaignAllowedProduct = "";
  let campaignState = "";
  let campaignExceptionProductt = "";
  let campaignAllowedProductt = "";
  let activeCampaignValues = "";

  campaignState = activeCampaignValues.subscriptionState
    ? activeCampaignValues.subscriptionState
    : "";

  useEffect(() => {
    const currentToken = AuthService.getCurrentToken();

    UserService.getSubscription(currentToken.access_token)
      .then((response) => {
        //console.log("new response", response); // Access the message here
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (
            error.response.data.message == "User has no active subscription."
          ) {
            setSubState("DEACTIVATED");
          }
          //console.log("new error", error.response.data.message); // Handle error message here
          //console.log("SUbState with new error", subState)
        } else {
          // console.log('An unexpected error occurred');
        }
      });

    setProductID(subscription.product_id);
    //setProductExpiry(subscription.expiry_date);
    const dateString = subscription.expiry_date; // ISO 8601 format
    const expiryDateObje = new Date(dateString);
    setExpiryDate(expiryDateObje);
    const currentDate = new Date();
    // console.log("dateString", dateString);
    // console.log("subState", subState);
    // console.log(expiryDateObje," < ", currentDate);
    if (
      subscription.product_id == 401 &&
      subscription.state != "7" &&
      subscription.state != "10"
    ) {
      setNewSubscriptionID(404);
    }
    if (
      subscription.product_id == 402 &&
      subscription.state != "7" &&
      subscription.state != "10"
    ) {
      setNewSubscriptionID(405);
    }
    if (
      subscription.product_id == 403 &&
      subscription.state != "7" &&
      subscription.state != "10"
    ) {
      setNewSubscriptionID(406);
    }
    if (
      subscription.product_id == 1005 ||
      subscription.product_id == 507 ||
      subscription.product_id == 614 ||
      subscription.product_id == 612
    ) {
      //setMissProductID(402);
    } else if (
      subscription.product_id == 1004 ||
      subscription.product_id == 506 ||
      subscription.product_id == 615 ||
      subscription.product_id == 611
    ) {
      //setMissProductID(401);
    } else if (
      subscription.product_id == 1006 ||
      subscription.product_id == 508 ||
      subscription.product_id == 616 ||
      subscription.product_id == 613
    ) {
      //setMissProductID(403);
    }
    setCampaignExpiry("06.01.2022");
    fornyKnapp(false);
    //setProductID(subscription.product_id);
    if (subscription.state === "3") {
      setSubState("ACTIVE");
      //setProductID(subscription.product_id);
    }
    if (subscription.state === "10") {
      setSubState("DEACTIVATED");
      setProductID("");
      //setProductID(subscription.product_id);
    }
    if (subscription.state === "1") {
      setSubState("TRIALING");
      //setProductID(subscription.product_id);
    }
    if (dateString != null && expiryDateObje < currentDate) {
      setSubState("DEACTIVATED");
      setProductID("");
    }
    if (subscription.state === "6" && expiryDateObje > currentDate) {
      setSubState("STOPPED");
    }

    if (subscription.state === "7") {
      setSubState("DEACTIVATED");
      setProductID("");
    }
    if (["101", "201"].includes(subscription.product_id)) {
      handleGetCredits();
    }

    if (["41", "42", "43"].includes(subscription.product_id)) {
      //setProductID("11");
      setCampaign(true);
      //setSubState('CAMPAGIN')
      subscriptions.forEach((subscriptionelement) => {
        if (subscriptionelement.id.toString() === subscription.product_id) {
          setCampaignPrice(subscriptionelement.price);
          setCampaignExpiry(subscriptionelement.campaignExpiry);
        }
      });
      if (subscription.product_id === "42") {
        setTopBanner(
          "Forny ditt abonnement på Fabel Basis (høstkampanje) til kampanjepris innen 01.04.2022",
        );
        fornyKnapp(false);
      }
    } else if (
      [
        "1",
        "11",
        "21",
        "31",
        "101",
        "102",
        "201",
        "202",
        "103",
        "701",
        "702",
        "104",
        "105",
        "106",
        "3002",
        "2001",
        "1001",
        "1002",
        "107",
        "109",
        "110",
      ].includes(subscription.product_id)
    ) {
      if (subscription.product_id === "1") {
        setSubscription({ ...subscription, product_id: "11" });
      }
      /*if (subscription.product_id === "109") {
        setSubscription({ ...subscription, product_id: "103" });
      }*/

      if (subscription.product_id === "110") {
        setSubscription({ ...subscription, product_id: "110" });
      }

      if (subscription.state === "3") {
        setSubState("ACTIVE");
        //setProductID(subscription.product_id);
      }
      if (subscription.state === "10") {
        //setProductID(subscription.product_id);
      }
      if (subscription.state === "1") {
        setSubState("TRIALING");
        //setProductID(subscription.product_id);
      }
      if (dateString != null && expiryDateObje < currentDate) {
        setSubState("DEACTIVATED");
        setProductID("");
      }
      if (subscription.state === "6" && expiryDateObje > currentDate) {
        setSubState("STOPPED");
      }
      if (subscription.state === "7") {
        setSubState("DEACTIVATED");
        setProductID("");
      }
      subscriptions.forEach((subscriptionelement) => {
        if (subscriptionelement.id.toString() === subscription.product_id) {
          setSubName(subscriptionelement.name);
        }
      });
    } else if (["410"].includes(subscription.product_id)) {
      setCampaign(true);
      setCampaignPrice("139");
      setCampaignExpiry("september 2022");
      setTopBanner();
    } else if (["603", "502"].includes(subscription.product_id)) {
      //setCampaign(true);
    } else if (["44"].includes(subscription.product_id)) {
      setCampaign(true);
      setSubState("ACTIVE");
      //setProductID("44");
      setCampaignPrice("139");
      setCampaignExpiry("30.06.2022");
      setTopBanner();
    }
  }, [subscription, setSubscription]);

  const campaignProduct = subscriptions.filter(
    (subscriptionobject) =>
      subscriptionobject.campaignPosition === "productselector" &&
      subscriptionobject.campaign === true &&
      subscriptionobject.subscriptionState.includes(subState) &&
      subscriptionobject.spesialtilbud ===
        window.sessionStorage.getItem("SpesialtilbudShow") &&
      currentDate.getTime() >
        new Date(subscriptionobject.startDate).getTime() &&
      new Date(subscriptionobject.endDate).getTime() > currentDate.getTime() &&
      ((expiryDate > currentDate && subscriptionobject.id === 618) ||
        (subscription.expiry_date != null &&
          expiryDate < currentDate &&
          subscriptionobject.id === 512) ||
        (subscription.expiry_date != null &&
          expiryDate > currentDate &&
          subscriptionobject.id === 621)),
  );
  //console.log(expiryDate, "<", currentDate);
  //console.log("campaignProduct", campaignProduct);

  const fornyKnapp = (state = false) => {
    return (
      <>
        :
        <div className="campaign-text">
          Kampanjepris. Abbonnementet gjelder t.o.m {campaignExpiry}, deretter
          er det løpende.
          <br />
          <Button onClick={handleFornyKampanje}>Forny kampanje</Button>
        </div>
      </>
    );
  };

  const handleClose = () => {
    setPopupChangeSub(false);
    setPopupForny(false);
    setPopupFornyKvitering(false);
    setPopupChangeSubKvitering(false);
  };

  const handleRadioChange = (e) => {
    setSelectedID(e.currentTarget.value);
    window.sessionStorage.setItem("selectedProduct", e.currentTarget.value);
    setPopupChangeSub(true);
    setChosenProd(e.currentTarget.value);
  };
  // console.log(window.dataLayer)
  // console.log("origin", window.location.origin);
  const vippsData = {
    product_id: selectedID,
    campaign_code: "UTMCAMPAIGN",
    redirect_url: window.location.origin + "/vipps-completed",
  };
  //return;
  const handleResumeSubs = () => {
    const currentToken = AuthService.getCurrentToken();

    UserService.resumeSubscription(currentToken.access_token).then(
      (response) => {
        setPopUpMsg(
          "Abonnementet ditt er gjenopptatt, vi ønsker deg hjertelig velkommen tilbake.",
        );
        setShowPopup(true);
        //console.log(response);
      },
      (error) => {
        //console.log(error);
        setPopUpMsg("Noe gikk galt, vi kunne ikke gjenoppta abonnementet ditt");
        setShowPopup(true);
      },
    );
  };
  const handleChangeSub = () => {
    //console.log("vippsData", vippsData);
    if (currentUser.payment_type === 6) {
      const currentToken = AuthService.getCurrentToken();
      UserService.vippsIntent(currentToken.access_token, vippsData).then(
        (vipps) => {
          if (vipps.status === 200) {
            window.sessionStorage.setItem(
              "vippsreference",
              vipps.data.reference,
            );
            window.location.href = vipps.data.confirmation_url;
            //setVippsButtonUrl(vipps.data.confirmation_url);
          } else {
          }
        },
      );
    } else {
      setDisableMode(true);
      //if (subState == "") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "subscription_reactivated",
      });
      //}
      if (subState === "STOPPED" && selectedID === subscription.product_id) {
        handleActivateSubscription();
      } else {
        //console.log("selectedID", selectedID);
        const currentToken = AuthService.getCurrentToken();
        if (currentUser.payment_type === 3) {
          UserService.changeStripeProduct(
            currentToken.access_token,
            selectedID,
          ).then(
            (response) => {
              UserService.getSubscription(currentToken.access_token).then(
                (subscription) => {
                  UserService.userSubscriptionLog(
                    "Subscription Card Page 1",
                    JSON.stringify(subscription),
                    currentUser,
                  );
                  setSubscription(subscription);
                },
                (error) => {
                  UserService.userSubscriptionLog(
                    "Subscription Card Page 1",
                    JSON.stringify(error),
                    currentUser,
                  );
                  //console.log(error);
                  //console.log("error geting subscription 4.0");
                },
              );
              UserService.paymentSwitchLogging(
                "successSubscriptionSwitchwithStripe - " +
                  JSON.stringify(response.status),
                JSON.stringify(response),
                currentUser.mobile,
                subState,
                selectedID,
                currentToken.access_token,
                "",
              );

              setPopupForny(false);
              setPopupRenewSub(false);
              setPopupChangeSub(false);
              setDisableMode(false);
              setChangeSubState(true);
              setPopupChangeSubKvitering(true);
              //console.log("error geting subscription 4.1");
            },
            (error) => {
              UserService.paymentSwitchLogging(
                "failedSubscriptionSwitchwithStripe - " +
                  JSON.stringify(error.status),
                JSON.stringify(error),
                currentUser.mobile,
                subState,
                selectedID,
                currentToken.access_token,
                "",
              );

              setDisableMode(false);
              setChangeSubState(false);
              setPopupChangeSubKvitering(true);
              //console.log("error geting subscription 4.3");
            },
          );
        } else {
          UserService.changeStrexProduct(
            currentToken.access_token,
            selectedID,
          ).then(
            (response) => {
              UserService.paymentSwitchLogging(
                "successSubscriptionSwitchwithStrex -" +
                  JSON.stringify(response.status),
                JSON.stringify(response),
                currentUser.mobile,
                subState,
                selectedID,
                currentToken.access_token,
                "",
              );
              //UserService.paymentSwitchLogging(response, currentUser.mobile, subState, selectedID);
              UserService.getSubscription(currentToken.access_token).then(
                (subscription) => {
                  UserService.userSubscriptionLog(
                    "Subscription Card Page 2",
                    JSON.stringify(subscription),
                    currentUser,
                  );
                  setSubscription(subscription);
                },
                (error) => {
                  UserService.userSubscriptionLog(
                    "Subscription Card Page 2",
                    JSON.stringify(error),
                    currentUser,
                  );
                  //console.log(error);
                  //console.log("error geting subscription 5.0");
                },
              );
              setPopupForny(false);
              setPopupRenewSub(false);
              setPopupChangeSub(false);
              setDisableMode(false);
              setChangeSubState(true);
              setPopupChangeSubKvitering(true);
              //console.log("error geting subscription 5.1");
            },
            (error) => {
              UserService.paymentSwitchLogging(
                "failedSubscriptionSwitchwithStrex -" +
                  JSON.stringify(error.status),
                JSON.stringify(error),
                currentUser.mobile,
                subState,
                selectedID,
                currentToken.access_token,
                "",
              );

              //UserService.paymentSwitchLogging(error, currentUser.mobile, subState, selectedID);
              setDisableMode(false);
              setChangeSubState(false);
              setPopupChangeSubKvitering(true);
              //console.log("error geting subscription 5.3");
            },
          );
        }

        /*UserService.changeSubscription(
          currentToken.access_token,
          selectedID
        ).then(
          (response) => {
            
            UserService.getSubscription(currentToken.access_token).then(
              (subscription) => {
                setSubscription(subscription);
              },
              (error) => {
                console.log(error);
                console.log("error geting subscription 3.0");
              }
            );
            setPopupForny(false);
            setPopupRenewSub(false);
            setPopupChangeSub(false);
            setDisableMode(false);
            setChangeSubState(true);
            setPopupChangeSubKvitering(true);
             console.log("error geting subscription 3.1");
          },
          () => {
            setDisableMode(false);
            setChangeSubState(false);
            setPopupChangeSubKvitering(true);
             console.log("error geting subscription 3.2");
          }
        );*/
      }
    }
  };

  const handleGetCredits = () => {
    const currentToken = AuthService.getCurrentToken();
    UserService.getOneBookCredits(currentToken.access_token).then((credits) => {
      setOneBookCredits(credits.credits);
    });
  };

  const handleActivateSubscription = () => {
    const currentToken = AuthService.getCurrentToken();
    UserService.activateSubscription(currentToken.access_token).then(
      (subscription) => {
        UserService.getSubscription(currentToken.access_token).then(
          (subscription) => {
            UserService.userSubscriptionLog(
              "Subscription Card Page 3",
              subscription,
              currentUser,
            );
            setSubscription(subscription);
            setPopupForny(false);
            setPopupChangeSub(false);
            setDisableMode(false);
            setChangeSubState(true);
            setPopupChangeSubKvitering(true);
            //console.log("error geting subscription 2.2");
          },
          (error) => {
            UserService.userSubscriptionLog(
              "Subscription Card Page 3",
              error,
              currentUser,
            );
            //console.log("error geting subscription");
          },
        );
      },
      (error) => {
        //console.log("error activating subscription");
        setChangeSubState(false);
        setPopupChangeSubKvitering(true);
      },
    );
  };

  const handleFornyKampanje = () => {
    setPopupForny(true);
  };

  const handleFornyKampanjeKvittering = () => {
    const currentToken = AuthService.getCurrentToken();

    UserService.subRenewalPOST(
      currentToken.access_token,
      subscription.sub_id,
      subscription.product_id,
    ).then(
      (result) => {
        setPopupForny(false);
        setPopupFornyKvitering(true);
        setSubscription({
          next_billing_date: subscription.next_billing_date,
          product_id: subscription.product_id,
          state: "3",
        });
      },
      (error) => {
        //console.log("error");
      },
    );
  };

  const baseProducts = subscriptions.filter(
    (sub) =>
      sub.campaign === false &&
      sub.main === true &&
      sub.id != newSubscriptionID &&
      sub.id != productID,
  );
  const campaignProducts = subscriptions.filter(
    (sub) =>
      sub.campaign === true &&
      sub.main === true &&
      expiryDate < currentDate &&
      subscription.expiry_date != null &&
      new Date(sub.endDate).getTime() > currentDate.getTime() &&
      sub.id != productID,
  );
  //console.log("expiryDate", subscription.expiry_date);
  //console.log("campaignProducts", campaignProducts);
  return (
    <Card className="card-main">
      <Modal className="popup" show={popupForny} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Bekreft fornyet abonnement til kampanjepris​
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Klikk "Aksepter" for å bekrefte fornyelse av
          <br />
          Fabel-abonnement til kampanjepris med bindingstid på seks måneder.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Avbryt
          </Button>
          <Button
            className="btn-success"
            onClick={handleFornyKampanjeKvittering}
          >
            Aksepter
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="popup" show={popupFornyKvitering} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Supert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Du har fornyet abonnementet til kampanjepris.</Modal.Body>
        <Modal.Footer>
          <Button className="btn-success" onClick={handleClose}>
            Lukk
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="popup"
        show={popupChangeSub}
        onHide={handleClose}
        disabled={disableMode}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {subscription.sub ? (
              <>Herlig! Nå kan du starte å lytte igjen</>
            ) : (
              <>
                Bekreft
                {subState == "TRIALING" ? (
                  <> abonnementet</>
                ) : (
                  <> abonnementsbytte</>
                )}
              </>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {subState == "TRIALING" ? (
            <>
              Abonnementsendringen vil skje umiddelbart. Du vil ikke lenger ha
              gratis prøveperiode.
            </>
          ) : (
            <>
              {subscription.sub ? (
                <> Ditt nye abonnement trer i kraft umiddelbart.</>
              ) : (
                <>
                  Abonnementsendringen vil skje umiddelbart og den nye prisen
                  trer først i kraft ved neste betalingsdato.
                </>
              )}
            </>
          )}
          {currentUser.payment_type == "6" ? <></> : null}
          {currentUser.payment_type == "0" ? <></> : null}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={disableMode}
          >
            Avbryt
          </Button>
          <Button
            className="btn-success"
            onClick={handleChangeSub}
            disabled={disableMode}
          >
            Aksepter
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        className="popup"
        show={popupRenewSub}
        onHide={handleClose}
        disabled={disableMode}
      >
        <Modal.Header closeButton>
          <Modal.Title>Bekreft abonnementet</Modal.Title>
        </Modal.Header>
        <Modal.Body>Abonnementsendringen vil skje umiddelbart.</Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={disableMode}
          >
            Avbryt
          </Button>
          <Button
            className="btn-success"
            onClick={handleChangeSub}
            disabled={disableMode}
          >
            Aksepter
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="popup"
        show={popupChangeSubKvitering}
        onHide={handleClose}
      >
        {changeSubState ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Bekreftet</Modal.Title>
            </Modal.Header>
            <Modal.Body>Abonnementet ditt er endret.</Modal.Body>
          </>
        ) : (
          <>
            {currentUser.payment_type == 0 ? (
              <>
                <Modal.Header closeButton>
                  <Modal.Title>Endre betalingsmåte</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Du må endre betalingsmåte for å velge nytt abonnement.
                </Modal.Body>
              </>
            ) : (
              <>
                <Modal.Header closeButton>
                  <Modal.Title>Ups!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Her gikk visst noe galt, prøv igjen om litt.
                </Modal.Body>
              </>
            )}
          </>
        )}

        <Modal.Footer>
          <Button className="btn-success" onClick={handleClose}>
            Lukk
          </Button>
        </Modal.Footer>
      </Modal>

      <Card.Header>
        <div className="bold-text">{title}</div>
      </Card.Header>
      <br />
      {/* subState==="DEACTIVATED" ? (
        <>
          <Col xs={10} lg={11} className="sub-col">
          Ditt Fabel-abonnement er stoppet. Du har fremdeles en kostnadsfri brukerkonto, og kan starte opp igjen når du måtte ønske. Velg abonnement under.
          </Col>
          <Col xs={10} lg={11} className="sub-col" style={{fontSize: '12px'}}>
          Ønsker du ikke å ha en kostnadsfri brukerkonto kan du kontakte kundeservice på kontakt@fabel.no
          </Col>

          <br />
        </>
      ) : null */}
      {/* CMPAGIN PRODUCT CHECK IN TXT FILE*/}
      {/*campaignState+" - "+subState+" - "+campaignState.indexOf(subState)*/}
      {campaignState.indexOf(subState) >= 0 &&
      campaignExceptionProductt.includes(subscription.product_id) === false ? (
        <>
          <div className="rnw-box" style={{ display: "none" }}>
            <div>
              <strong>
                Ditt abonnement på {subname} utløper{" "}
                {formatDateString(subscription.expiry_date)}{" "}
              </strong>
            </div>
            <br />
            <Button onClick={handleResumeSubs}>Gjenoppta abonnementet</Button>
          </div>

          <div
            className="rnw-box stopped-container"
            style={{ display: "none" }}
          >
            <div className="stopped-heading">
              <strong>{activeCampaignValues.welcomeBack}</strong>
            </div>
            <div className="stopped-tagline">
              <strong style={{ fontWeight: "bold" }}>
                {activeCampaignValues.seasonTagLine}
              </strong>
              <br />
              {activeCampaignValues.seasonTagLineTwo}
            </div>
          </div>
        </>
      ) : null}
      <ListGroup className="sub-list2">
        {campaignProduct.map((value, key) => {
          const campaignExceptionProduct = value.exceptionProduct;
          const campaignExceptionProductt =
            campaignExceptionProduct == "all"
              ? "all"
              : campaignExceptionProduct.split(", ");
          const campaignAllowedProduct = value.allowedProduct;
          const campaignAllowedProductt =
            campaignAllowedProduct == "all"
              ? "all"
              : campaignAllowedProduct.split(", ");
          //if((subscription.product_id=="621" && subState=="DEACTIVATED") || (subscription.product_id!="621")){
          if (
            campaignExceptionProductt.includes(subscription.product_id) ===
            false
          ) {
            if (
              campaignAllowedProductt.includes(subscription.product_id) ===
                true ||
              campaignAllowedProductt == "all"
            ) {
              //console.log("cvcvc", value.id)
              return (
                <>
                  <ListGroup.Item
                    key={value.id}
                    className="789 product-displayy stopped-user-product"
                  >
                    <div style={{ color: "#FFFFFF" }}>
                      <strong style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {value.seasonTagLine}
                      </strong>
                      <br />
                      <strong style={{ fontWeight: "bold" }}>
                        {value.seasonTagLineTwo}
                      </strong>
                      <br />
                      {value.seasonTagLineThree}
                    </div>
                    <div
                      className="product-radio"
                      style={{
                        float: "left",
                        width: "73px",
                        lineHeight: "73px",
                      }}
                    >
                      <Radio
                        color="success"
                        onChange={handleRadioChange}
                        value={value.id}
                        className="radio-btn"
                      />
                    </div>
                    <div className="product-info">
                      <Row className="radio-row">
                        <Col xs={10} lg={11} className="sub-col">
                          <div className="user-product-info">
                            <div className="product-name">
                              <p className="stopped-4001-name">
                                {value.nameLarge}
                                {/* value.saleprice ? 
                              <><strike>{value.price} {value.priceMonths}</strike> {value.saleprice} {value.priceMonths}</>
                              :
                              <>{value.price}  {value.priceMonths}</>
                              */}
                              </p>
                              <p className="stopped-4001-text">
                                {value.slogan}
                                <br />
                                {/*value.concurrentStreams} {" "}
                                {value.concurrentStreams === 1 ? (
                                  <>samtidig strømming</>
                                ) : (
                                  <>samtidige strømminger</>
                                )}, {value.profiles */}
                                {value.profiles}
                                {value.profiles === 1 ? (
                                  <> profil kan lytte om gangen</>
                                ) : (
                                  <> profiler kan lytte samtidig</>
                                )}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </ListGroup.Item>
                </>
              );
            }
          }
          //}
        })}

        {/*Display user product*/}
        {subscriptions
          .filter((sub) => sub.id == productID)
          .filter((sub) => subState !== "DEACTIVATED")
          .map((sub) => (
            <ListGroup.Item
              key={sub.id}
              className="product-display "
              style={{ backgroundColor: sub.id == "603" ? "#46387f" : "" }}
            >
              <>
                <div className="desktop-only" style={{ width: "700px" }}>
                  <Row className="radio-row">
                    <Col xs={2}>
                      <Radio
                        color="success"
                        disabled={false}
                        checked={sub.id == productID}
                        onChange={handleRadioChange}
                        value={sub.id}
                        className="radio-btn"
                      />
                    </Col>
                    <Col xs={9}>
                      <div className="bold-text">Gjeldende abonnement:</div>

                      <p className="exbold-text">
                        {sub.id == 513 || sub.id == 514 || sub.id == 515 ? (
                          <>{sub.selectedName ? sub.selectedName + " " : ""}</>
                        ) : (
                          <>
                            {sub.name ? sub.name + " " : ""}
                            {sub.saleprice ? (
                              <span>
                                <strike className="strikePrice">
                                  {sub.price} {sub.priceMonths}
                                </strike>{" "}
                                {sub.saleprice} {sub.priceMonths}
                              </span>
                            ) : (
                              <span class="exbold-text">
                                {sub.id == 621 || sub.id == 512 || sub.id == 622
                                  ? ""
                                  : sub.price + " " + sub.priceMonths}
                              </span>
                            )}
                          </>
                        )}

                        <br />
                      </p>
                      <p>
                        <Row className="radio-row">
                          <Col xs={10} lg={11} className="sub-col">
                            {/*}
                            {sub.concurrentStreams.toString()}{" "}
                            {sub.concurrentStreams === 1 ? (
                              <>samtidig strømming.</>
                            ) : (
                              <>samtidige strømminger.</>
                            )}{" "}
                            {sub.profiles.toString()}{" "}
                            {*/}
                            {sub.slogan}{" "}
                            {sub.profiles == 1 ? (
                              <> {sub.profiles} profil kan lytte om gangen.</>
                            ) : (
                              <>{sub.profiles} profiler kan lytte samtidig</>
                            )}
                          </Col>
                        </Row>
                        {/*productID==101 || productID==201 ? 
                        (<>
                        <Row className="radio-row note-for-101-201">
                          <Col xs={10} lg={11} className="sub-col">
                              <div>
                                Fra 1.mai trenge​r ​du ikke begrense deg til kun en bok i m&aring;neden. 
                                Du har f&aring;tt abonnementet Standard og kan lytte ubegrenset til alle lydbøker og lydserier i hele sommer. 
                                Du beholder samme lave pris til 1. august 2024. Se informasjonen vi har sendt deg p&aring; e-post eller SMS.
                              </div>
                            
                          </Col>
                        </Row>
                        </>)
                        : (<></>)*/}
                      </p>
                      {sub.renewButton == true &&
                      sub.renewCondition == subState ? (
                        <>
                          <p>
                            <Button
                              className="btn-success"
                              onClick={handleFornyKampanje}
                            >
                              Forny kampanje
                            </Button>
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="mobile-only" style={{ width: "360px" }}>
                  <Row className="radio-row">
                    <Col xs={2}>
                      <Radio
                        color="success"
                        disabled={false}
                        checked={sub.id == productID}
                        onChange={handleRadioChange}
                        value={sub.id}
                        className="radio-btn"
                        style={{ top: "25%" }}
                      />
                    </Col>
                    <Col xs={10}>
                      <div className="bold-text">Gjeldende abonnement:</div>

                      <p className="exbold-text">
                        {sub.name ? sub.name + "" : ""}
                        {sub.saleprice ? (
                          <span>
                            <strike className="strikePrice">
                              {sub.price} {sub.priceMonths}
                            </strike>{" "}
                            {sub.saleprice} {sub.priceMonths}
                          </span>
                        ) : (
                          <span class="exbold-text">
                            {sub.id == 501 || sub.id == 601
                              ? ""
                              : sub.price + " " + sub.priceMonths}
                          </span>
                        )}

                        <br />
                        <strong className="exbold-text">{sub.slogan}</strong>
                      </p>

                      <p>
                        <Row className="radio-row">
                          <Col xs={10} lg={11} className="sub-col">
                            {sub.concurrentStreams.toString()}{" "}
                            {sub.concurrentStreams === 1 ? (
                              <>samtidig strømming.</>
                            ) : (
                              <>samtidige strømminger.</>
                            )}{" "}
                            {sub.profiles.toString()}{" "}
                            {sub.profiles == 1 ? (
                              <>profil kan lytte om gangen.</>
                            ) : (
                              <>profiler kan lytte samtidig</>
                            )}
                          </Col>
                        </Row>
                        {/*productID==101 || productID==201 ? 
                        (<>
                        <Row className="radio-row note-for-101-201">
                          <Col xs={10} lg={11} className="sub-col">
                              <div>
                                Fra 1.mai trenge​r ​du ikke begrense deg til kun en bok i m&aring;neden. 
                                Du har f&aring;tt abonnementet Standard og kan lytte ubegrenset til alle lydbøker og lydserier i hele sommer. 
                                Du beholder samme lave pris til 1. august 2024. Se informasjonen vi har sendt deg p&aring; e-post eller SMS.
                              </div>
                            
                          </Col>
                        </Row>
                        </>)
                        : (<></>) */}
                      </p>
                      {sub.renewButton == true &&
                      sub.renewCondition == subState ? (
                        <>
                          <p>
                            <Button
                              className="btn-success"
                              onClick={handleFornyKampanje}
                            >
                              Forny kampanje
                            </Button>
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </div>
              </>
            </ListGroup.Item>
          ))}

        <div className="card-header bold-text">Våre abonnementer</div>

        {/* Display main products */}

        {campaignProducts != ""
          ? campaignProducts.map((sub) => (
              <ListGroup.Item key={sub.id} className="product-display">
                <div className="product-radio">
                  <Radio
                    color="success"
                    disabled={campaign}
                    onChange={handleRadioChange}
                    value={sub.id}
                    className="radio-btn"
                  />
                </div>
                <div className="product-info">
                  <Row className="radio-row">
                    <Col xs={10} lg={11} className="sub-col">
                      {campaign && sub.id == productID ? (
                        <>
                          <div className="">
                            Fabel abonnement: {sub.price} kr/mnd med bindingstid
                            til {campaignExpiry}.
                          </div>
                          <div className="bold-text">{sub.slogan}</div>
                          <div className="">
                            Fabel abonnement: {sub.price} kr/mnd med bindingstid
                            til {campaignExpiry}.
                          </div>
                          <div className="bold-text">{sub.slogan}</div>
                        </>
                      ) : (
                        <div className="user-product-info">
                          <>
                            <div
                              className="product-name"
                              style={{ minWidth: "200px" }}
                            >
                              <p>
                                {sub.name}{" "}
                                {sub.saleprice ? (
                                  <>
                                    <strike className="strikePrice">
                                      {sub.price} {sub.priceMonths}{" "}
                                    </strike>{" "}
                                    {sub.saleprice} {sub.priceMonths}{" "}
                                    {sub.salepriceTag}{" "}
                                  </>
                                ) : (
                                  <>
                                    {sub.price} {sub.priceMonths}{" "}
                                  </>
                                )}
                              </p>
                            </div>
                            <strong className="exbold-text">
                              <span>&nbsp;</span>
                            </strong>
                          </>
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row className="radio-row">
                    <Col xs={10} lg={11} className="sub-col">
                      {/* }
                    {sub.concurrentStreams.toString()}{" "}
                    {sub.concurrentStreams === 1 ? (
                      <>samtidig strømming.</>
                    ) : (
                      <>samtidige strømminger.</>
                    )}{" "}
                    {*/}
                      {sub.slogan} {sub.profiles.toString()}{" "}
                      {sub.profiles == 1 ? (
                        <>profil kan lytte om gangen</>
                      ) : (
                        <>profiler kan lytte samtidig</>
                      )}
                    </Col>
                  </Row>

                  <Row className="radio-row">
                    <Col xs={10} lg={11} className="sub-col">
                      {campaign && sub.id == productID ? (
                        <>
                          <div className="campaign-text">
                            Kampanjepris. Abonnementet gjelder t.o.m{" "}
                            {campaignExpiry}, deretter går det automatisk over
                            til et løpende abonnement med en månedspris på 179
                            kroner.
                            <br />
                          </div>
                          {subscription.product_id === "41" ? (
                            <>
                              <Button
                                className="btn-success"
                                onClick={handleFornyKampanje}
                              >
                                Forny kampanje
                              </Button>
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="radio-row">
                    <Col xs={10} lg={11} className="sub-col">
                      {!campaign && sub.id == productID ? (
                        <>
                          <div className="bold-text">
                            Neste betalingsdato er:{" "}
                            {formatDateString(subscription.next_billing_date)}
                          </div>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                </div>
              </ListGroup.Item>
            ))
          : baseProducts.map((sub) => (
              <ListGroup.Item key={sub.id} className="product-display">
                <div className="product-radio">
                  <Radio
                    color="success"
                    disabled={campaign}
                    onChange={handleRadioChange}
                    value={sub.id}
                    className="radio-btn"
                  />
                </div>
                <div className="product-info">
                  <Row className="radio-row">
                    <Col xs={10} lg={11} className="sub-col">
                      {campaign && sub.id == productID ? (
                        <>
                          <div className="">
                            Fabel abonnement: {sub.price} kr/mnd med bindingstid
                            til {campaignExpiry}.
                          </div>
                          <div className="bold-text">{sub.slogan}</div>
                          <div className="">
                            Fabel abonnement: {sub.price} kr/mnd med bindingstid
                            til {campaignExpiry}.
                          </div>
                          <div className="bold-text">{sub.slogan}</div>
                        </>
                      ) : (
                        <div className="user-product-info">
                          <>
                            <div
                              className="product-name"
                              style={{ minWidth: "200px" }}
                            >
                              <p>
                                {sub.name}{" "}
                                {sub.saleprice ? (
                                  <>
                                    <strike className="strikePrice">
                                      {sub.price} {sub.priceMonths}{" "}
                                    </strike>{" "}
                                    {sub.saleprice} {sub.priceMonths}{" "}
                                    {sub.salepriceTag}{" "}
                                  </>
                                ) : (
                                  <>
                                    {sub.price} {sub.priceMonths}{" "}
                                  </>
                                )}
                              </p>
                            </div>
                            <strong className="exbold-text">
                              <span>&nbsp;</span>
                            </strong>
                          </>
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row className="radio-row">
                    <Col xs={10} lg={11} className="sub-col">
                      {/* }
                    {sub.concurrentStreams.toString()}{" "}
                    {sub.concurrentStreams === 1 ? (
                      <>samtidig strømming.</>
                    ) : (
                      <>samtidige strømminger.</>
                    )}{" "}
                    {*/}
                      {sub.slogan} {sub.profiles.toString()}{" "}
                      {sub.profiles == 1 ? (
                        <>profil kan lytte om gangen</>
                      ) : (
                        <>profiler kan lytte samtidig</>
                      )}
                    </Col>
                  </Row>

                  <Row className="radio-row">
                    <Col xs={10} lg={11} className="sub-col">
                      {campaign && sub.id == productID ? (
                        <>
                          <div className="campaign-text">
                            Kampanjepris. Abonnementet gjelder t.o.m{" "}
                            {campaignExpiry}, deretter går det automatisk over
                            til et løpende abonnement med en månedspris på 179
                            kroner.
                            <br />
                          </div>
                          {subscription.product_id === "41" ? (
                            <>
                              <Button
                                className="btn-success"
                                onClick={handleFornyKampanje}
                              >
                                Forny kampanje
                              </Button>
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="radio-row">
                    <Col xs={10} lg={11} className="sub-col">
                      {!campaign && sub.id == productID ? (
                        <>
                          <div className="bold-text">
                            Neste betalingsdato er:{" "}
                            {formatDateString(subscription.next_billing_date)}
                          </div>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                </div>
              </ListGroup.Item>
            ))}
      </ListGroup>
      <div className="explanatory-text" style={{ display: "none" }}>
        *Samtidig strømming betyr hvor mange personer som kan lytte samtidig, på
        hver sin enhet. ​Med egen profil kommer hver bruker rett inn i sin egen
        bok og bokhylle
      </div>
    </Card>
  );
};

export default SubscriptionCard;
