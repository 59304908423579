import React, { useState, useRef, useEffect } from 'react'
import { Card, Container, Form, FormControl, Button, Row, Col } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import ClientService from '../services/ClientService';
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import Cookies from 'universal-cookie';

import '../styles/forgotten-password.css'


const ForgottenPassword = () => {
    const cookies = new Cookies();
    const [msisdn, setMsisdn] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [password, setPassword] = useState('....')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [step, setStep] = useState(1)
    const [accessToken, setAccessToken] = useState('')
    const history = useHistory();
    const [smsCode1, setSmsCode1] = useState('');
    const [smsCode2, setSmsCode2] = useState('');
    const [smsCode3, setSmsCode3] = useState('');
    const [smsCode4, setSmsCode4] = useState('');
    const sms1 = useRef(null)
    const sms2 = useRef(null)
    const sms3 = useRef(null)
    const sms4 = useRef(null)
    const pwdRef = useRef(null)
    const [userMessage, setUserMessage] = useState({
        showMessage: false,
        message: '',
        messageType: null
    })

    useEffect(() => {
        
    })

    const backToLogin = () => {
        history.push('/')
    }

    const handleChange = e => {
        setMsisdn('47' + e.currentTarget.value)
    }

    const clearMessage = () => {
        setUserMessage({
            showMessage: false,
            message: '',
            messageType: null
        }); 
    }

    const handleChangeSms = e => {
        const name = e.currentTarget.name
        let value = e.currentTarget.value
        if (e.currentTarget.value.length > 1) {
            value = value.slice(0, 4)
            setSmsCode1(value[0])
            setSmsCode2(value[1])
            setSmsCode3(value[2])
            setSmsCode4(value[3])
        } else {
            switch(name) {
                case 'sms1':
                    setSmsCode1(value)
                    break;
                case 'sms2':
                    setSmsCode2(value)
                    break;
                case 'sms3':
                    setSmsCode3(value)
                    break;
                case 'sms4':
                    setSmsCode4(value)
                    break;                    
                default:
                    break;
            }
        }
        
        
    }
    /*const handleChangeSms = e => {
        const name = e.currentTarget.name
        let value = e.currentTarget.value
        if (e.currentTarget.value.length > 1) {
            value = value.slice(0, 4)
        }
        setSmsCode1(value[0])
        setSmsCode2(value[1])
        setSmsCode3(value[2])
        setSmsCode4(value[3])
        switch(name) {
            case 'sms1':
                setSmsCode1(value)
                break;
            case 'sms2':
                setSmsCode2(value)
                break;
            case 'sms3':
                setSmsCode3(value)
                break;
            case 'sms4':
                setSmsCode4(value)
                break;                    
            default:
                break;
        }
    }*/

    const handleChangePswd = e => {
        setPassword(e.currentTarget.value)
    }

    const handleChangeConfirmPswd = e => {
        setConfirmPassword(e.currentTarget.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (msisdn.length == 10) {
            ClientService.getAccessToken().then(response => {
                setAccessToken(response)
                ClientService.sendSMS(response, msisdn).then(response => {
                    setUserMessage({
                        showMessage: true,
                        message: 'Vi har sendt deg en sms.',
                        messageType: "alert-success"
                    });    
                    setStep(2)
                }, () => {
                    console.log('dddd')
                })
            })    
        } else {
            console.log('to long or short phonenr')
            setUserMessage({
                showMessage: true,
                message: 'Ikke gyldig mobilnummer.',
                messageType: "alert-danger"
            });   
        }
    }

    const handleSubmitNew = (e) => {
        e.preventDefault();
        ClientService.getAccessToken().then(response => {
            setAccessToken(response)
            ClientService.sendSMS(response, msisdn).then(response => {
                setUserMessage({
                    showMessage: true,
                    message: 'Vi har sendt deg en ny sms på nytt.',
                    messageType: "alert-success"
                });    
                setStep(2)
            }, () => {
            })
        })
    }

   


    const handleSubmitPassword = (e) => {
        e.preventDefault();
        
        if (password === confirmPassword) {
            setIsDisabled(true)
            const smsCodeConcatinated = smsCode1 + smsCode2 + smsCode3 + smsCode4
            ClientService.changePassword(accessToken, password, smsCodeConcatinated, msisdn)
                .then(response => {
                    console.log('password changed');
                    AuthService.login(msisdn, password).then(
                        () => {
                         // setSubmitState(stateUpdate);
                          const currentToken = AuthService.getCurrentToken();
                          const encryptedUserPass = AuthService.getEncryptedCredentials(msisdn, password);
                          
                          //console.log('encryptedUserPass', encryptedUserPass);
                          //return;
                          const userSubscription = UserService.getSubscription(currentToken.access_token)
                          .then(subscription => {
                                UserService.userSubscriptionLog("Forgot Pass Page 1", JSON.stringify(subscription), "");
                                
                            }, error => {
                                UserService.userSubscriptionLog("Forgot Pass Page 1", JSON.stringify(error), "");
                               
                            });
                          const dataPromise = userSubscription
                            .then((response) => {
                              
                                const encryptedData = encryptedUserPass
                                .then((response) => {
                                    //const link = process.env.REACT_APP_FABEL_WP_URL+"SSO/sso_login_v2.php?secret=fabel_sso_key&url="+process.env.REACT_APP_FABEL_WP_URL+"lydbok&cred=" +encodeURI(response)
                                    //const link = process.env.REACT_APP_FABEL_WP_URL+"SSO/sso_login_v2.php?secret=fabel_sso_key&url="+process.env.REACT_APP_FABEL_WP_URL+"lydbok&username=" +btoa(username) +"&password=" +btoa(password)
                                    //cookies.set('link', link, { path: '/' });
                                    history.push("min-side");
                                    window.location.reload();
                                })
                              
                            })
                            .catch((error) => {
                              console.log("error: " + error);
                              history.push("/min-side");
                              //console.log("fabel_connect_token_v22", window.sessionStorage.getItem('fabel_connect_token_v2'));
                              const encryptedData = encryptedUserPass
                                .then((response) => {
                                    //const link = process.env.REACT_APP_FABEL_WP_URL+"SSO/sso_login_v2.php?secret=fabel_sso_key&url="+process.env.REACT_APP_FABEL_WP_URL+"lydbok&cred=" +encodeURI(response)
                                    //const link = process.env.REACT_APP_FABEL_WP_URL+"SSO/sso_login_v2.php?secret=fabel_sso_key&url="+process.env.REACT_APP_FABEL_WP_URL+"lydbok&username=" +btoa(username) +"&password=" +btoa(password)
                                    //cookies.set('link', link, { path: '/' });
                                })
                              
                            });
                        },
                        (error) => {
                          //const resMessage = "Passord og mobilnummer stemmer ikke overrens.";
                          /*setSubmitState({
                            submitted: true,
                            isError: true,
                            message: resMessage,
                          });*/
                        }
                      );
                    //goToLogin()
                }, error => {
                    setIsDisabled(false)
                    setSmsCode1('');
                    setSmsCode2('');
                    setSmsCode3('');
                    setSmsCode4('');
                    setUserMessage({
                        showMessage: true,
                        message: 'Feil sms kode.',
                        messageType: "alert-danger"
                    });    
    
                    console.log('password changed fail')
                })
        } else {
            
            console.log('passwords do not match')
            setUserMessage({
                showMessage: true,
                message: 'Passordene samsvarer ikke.',
                messageType: "alert-danger"
            });    
        }

    }
    const goToLogin = () => {
        history.push('/')
    }


    const handleFocus = e => {
        if (e.currentTarget.value.length === 1) {
            switch(e.currentTarget.name) {
                case "sms1":
                    sms2.current.focus()
                    sms2.current.select()
                    break;
                case "sms2":
                    sms3.current.focus()
                    sms3.current.select()
                    break;
                case "sms3":
                    sms4.current.focus()
                    sms4.current.select()
                    break;
                case "sms4":
                    pwdRef.current.focus()
                    break;
                default:
                    break;
                }
        }
    }

    const handleFocuss = e => {
        if (e.currentTarget.value.length === 1) {
            switch(e.currentTarget.name) {
                case "sms1":
                    sms1.current.select()
                    break;
                case "sms2":
                    sms2.current.select()
                    break;
                case "sms3":
                    sms3.current.select()
                    break;
                case "sms4":
                    sms4.current.select()
                    break;
                default:
                    break;
                }
        }
    }
    switch(step) {
        case 1:
            return (
        <Container className="password-form-container">
            <Card className="title-card">
                <Card.Title className="title">
                    Endre passord
                </Card.Title>
            {/* alert-message */}
            <div className={userMessage.messageType + " alert card-alert"} role="alert">
                {userMessage.message}
            </div>   


                <Card.Text className="card-text">
                    Skriv inn telefonnummeret du bruker til å logge inn i Fabel så sender vi deg instruksjoner for å lage et nytt passord.
                </Card.Text>
            </Card>
            <Form className="password-form" onSubmit={handleSubmit}>
                <span className="msisdn">Mobilnummer</span>
                <FormControl
                name="msisdn"
                placeholder=""
                type="number"
                onChange={handleChange}
                className="fabel-input"
                />
                <Button
                className="login-btn btn-fabel"
                type="submit"
                >
                SEND SMS
                </Button><br/>
                <Button variant="link" className="link-btn" onClick={backToLogin}>Tilbake til innlogging</Button>
            </Form>
            
        </Container>
    )
            
        case 2:
    return (
    
        <Container className="password-form-container">
            <Card className="title-card">
                <Card.Title className="title">
                    Endre passord
                </Card.Title>
                {/* alert-message */}
             <div className={userMessage.messageType + " alert card-alert"} role="alert">
                {userMessage.message}
            </div>   

            </Card>
            <Form className="password-form" onSubmit={handleSubmitPassword}>
                <span className="msisdn">SMS-kode</span>
                <Row>
                    <Col>
                <FormControl
                name="sms1"
                className="smsinput fabel-input"
                ref={sms1}
                placeholder=""
                value={smsCode1}
                onKeyUp={handleFocus}
                type="number"
                onFocus={handleFocuss}
                onChange={handleChangeSms}
                />
                </Col><Col>
                <FormControl
                name="sms2"
                className="smsinput fabel-input"
                placeholder=""
                ref={sms2}
                value={smsCode2}
                type="number"
                onKeyUp={handleFocus}
                 onFocus={handleFocuss}
                onChange={handleChangeSms}
                />
                </Col><Col>
                <FormControl
                name="sms3"
                className="smsinput fabel-input"
                placeholder=""
                value={smsCode3}
                ref={sms3}
                type="number"
                 onFocus={handleFocuss}
                onKeyUp={handleFocus}
                onChange={handleChangeSms}
                />
                </Col><Col>
                <FormControl
                name="sms4"
                className="smsinput fabel-input"
                placeholder=""
                type="number"
                ref={sms4}
                value={smsCode4}
                 onFocus={handleFocuss}
                onKeyUp={handleFocus}
                onChange={handleChangeSms}
                />
                </Col>
                <Col xs={4}>
                <Button className="btn-fabel" onClick={handleSubmitNew}>
                    Send ny kode
                </Button>
                </Col>
                </Row>
                <Row className="password-input">
                <span className="msisdn">Nytt passord</span>
                <FormControl
                name="password"
                className="password-inputfield fabel-input"
                placeholder=""
                type="password"
                ref={pwdRef}
                onChange={handleChangePswd}
                /></Row><Row className="password-input">
                <span className="msisdn">Gjenta passord</span>
                <FormControl
                name="password-confirm"
                placeholder=""
                className="password-inputfield fabel-input"
                type="password"
                onChange={handleChangeConfirmPswd}
                /></Row>
                <Button
                disabled={isDisabled}
                className="confirm-pass-btn btn-fabel"
                type="submit"
                >
                Bekreft
                </Button><br/>
                <Button variant="link" className="link-btn" onClick={backToLogin}>Tilbake til innlogging</Button>
            </Form>
            
        </Container>
    )
        default:
            break;
    }

}

export default ForgottenPassword
