import React from 'react'
import '../styles/footer.css'

const Footer = () => {
    return (
		
		<footer>
			<div className="footer">
				<div>
					<a href="https://fabel.no/om-oss-abonnementstjenesten-fabel/">Om Fabel</a>
				</div>
				<div>
					<a href="https://apps.apple.com/no/app/fabel/id1174300811"
						><img
						src="https://fabel.no/wp-content/plugins/fabel/assets/app_store_commons_badge_transparent.svg"
						alt=""
						/></a>
					<a href="https://play.google.com/store/apps/details?id=no.fabel"
						><img
						src="https://fabel.no/wp-content/plugins/fabel/assets/google_play_commons_badge_transparent.svg"
						alt=""
						/></a>
				</div>
				<div className="fade">
					
				</div>
			</div>
		</footer>
		
    )
}

export default Footer