import React, { useEffect, useState } from 'react'
import { Redirect } from "react-router-dom";
import { Card, Col, Container, Image, Row, Button } from 'react-bootstrap'
import AuthService from '../../services/AuthService'
import UserService from '../../services/UserService'
import './styles/card.css'
import { useHistory } from 'react-router'

const BookCard = ({title, user}) => {
    const [books, setBooks] = useState([])
    const [redirect, setRedirect] = useState(null);
    const [booksReady, setBooksReady] = useState([false])
    const history = useHistory();
    useEffect(() => {
        const currentToken = AuthService.getCurrentToken();
        
        UserService.getBooks(currentToken.access_token).then((bookshelf) => {
            const reversedArray = [...bookshelf.bookshelf].reverse();
            setBooks(reversedArray)
            setBooksReady(true)
        
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.message) {
              
              const errorMsg = error.response.data.message;
              if(errorMsg.includes("Token not found") || errorMsg.includes("Token expired") || errorMsg.includes("Invalid Token")){
                UserService.userGetLog("error BookCard Page: "+currentToken.access_token, errorMsg, "");
                AuthService.logout();
                setRedirect({ redirect: "/logg-inn" });
              }
              //console.log("new error  mypage 1", error.response.data.message); // Handle error message here
            } else {
              console.log('An unexpected error occurred');
            }
          });

        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    
    const downloadBook = (link) => {
        const currentToken = AuthService.getCurrentToken();
        UserService.downloadBook(currentToken.access_token, link)
        .then(response => {
            window.location.href = response.download_url
        }, (e) => {
            console.log(e)
        })
    }

    const goToPurchasedBooks = (books) => {
        history.push({
            pathname: '/min-side/boker',
            state: {books: books}
        })
    }
    //redirect if not logged in
    if (redirect) {
        return <Redirect to={redirect.redirect} />;
    }
    return (
        <Card className = "card-main">
            <Card.Header>{title}</Card.Header>

            {booksReady && books ?
            <>
                {books.slice(0,4).map(book => (
                    <Card key={book.isbn} className="book-card">
                        <Container>
                        <Row className="align-items-center">
                            <Col><Image src={book.image ? book.image : "https://fabel.no/wp-content/uploads/2021/12/New-default-cover.jpg"} className="book-thumbnail" thumbnail></Image></Col>
                            <Col className="book-text align-items-center"><b>{book.title}</b><br/>{book.author ? book.author : ""}</Col>
                            <Col className="btn-download-book-col"><Button className="btn-download-book float-right btn-fabel" onClick={()=> downloadBook(book.download)}>Last ned</Button></Col>
                        </Row>
                        </Container>
                        </Card>
                    
                ))}
                {books.length > 4 ? <>
                    <Row className="align-items-center">
                        <Col>
                        <b><div className="explanatory-text">Se alle kjøpte bøker</div></b>
                        </Col>
                        <Col>
                        <Button className="btn-see-all-books float-right btn-fabel" onClick={() => goToPurchasedBooks(books)}>➜</Button>
                        </Col>
                    </Row>
                </> : null}
                </>
             : null}

        </Card>
    )
}

export default BookCard
