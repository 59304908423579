const ValidateInput = ( msisdn, password) => {
    let stateUpdate = {
        
            submitted: true,
            isError: true,
            message: "Begge felt må være fylt ut",
        
    }
    if (msisdn === "" && password === "") {
        stateUpdate = {
            submitted: true,
            isError: true,
            message: "Begge felt må være fylt ut",
        }
    } else if (msisdn === "") {
        stateUpdate = {
            submitted: true,
            isError: true,
            message: "Mobilnummer må være fylt ut",
        }
    } else if (password === "") {
        stateUpdate = {
            submitted: true,
            isError: true,
            message: "Passord må være fylt ut",
        }
    } else if (msisdn.length === 7) {
        stateUpdate = {
            submitted: true,
            isError: true,
            message: "Mobilnummer må være 8 siffer.",
        }
    } else {
        stateUpdate = {
            submitted: true,
            isError: false,
            message: "Informasjon validert",
        }
    }


    return stateUpdate

}

export default ValidateInput