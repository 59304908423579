import "../styles/receipt.css";
import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
const Receipt = () => {
    //const [searchParams] = useSearchParams();
    const [recieptList, setRecieptList] = useState([]);
    const [redirect, setRedirect] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [receiptDetails, setReceiptDetails] = useState(false);
    const [currentUser, setCurrentUser] = useState({
    username: "",
    });
    useEffect(() => {
        const currentToken = AuthService.getCurrentToken();
        console.log("currentToken", currentToken);
        if (!currentToken) {

            setRedirect({ redirect: "/" });
        } else {
            UserService.getReceiptList(currentToken.access_token).then(
                (receipt) => {
                    setRecieptList(receipt);
                    //console.log(receipt)
                },
                (error) => {

                }
            );
            UserService.getUserFromToken(currentToken.access_token).then(
                (user) => {
                     UserService.userGetLog("Recieept Page 1", user, currentUser);
                  setCurrentUser(user);
                }
              ).catch(error => {
                if (error.response && error.response.data && error.response.data.message) {
                  
                  const errorMsg = error.response.data.message;
                  if(errorMsg.includes("Token not found") || errorMsg.includes("Token expired") || errorMsg.includes("Invalid Token")){
                    UserService.userGetLog("error Recieept Page 1: "+currentToken.access_token, errorMsg, JSON.stringify(currentUser));
                    AuthService.logout();
                    setRedirect({ redirect: "/logg-inn" });
                  }
                  console.log("new error  mypage 1", error.response.data.message); // Handle error message here
                } else {
                  console.log('An unexpected error occurred');
                }
              });;
        }
    }, []);
    if (redirect) {
        return <Redirect to={redirect.redirect} />;
    }
    const toggle = (toggleReference) => {
        setShowDetails(true);
        setReceiptDetails(toggleReference);
        
        //console.log("filteredReceipt", filteredReceipt);
    };
    //console.log(email);
    return (
         <div className="receipt">
            <div>
            <br />
            </div>
            <div className="row receiptList">
                <div className="col-lg-4 col-md-3 col-4 recieptHeads">
                    Receipt Number
                </div>
                <div className="col-lg-4 col-md-3 col-3 recieptHeads">
                    Price
                </div>
                <div className="col-lg-3 col-md-3 col-3 recieptHeads">
                    Type
                </div>
                <div className="col-lg-1 col-md-3 col-2 recieptHeads">
                    Action
                </div>
                {recieptList.map((val, key) => (
                    <>
                    <div className="col-lg-4 col-md-3 col-4">
                        {val.receipt_number}
                    </div>
                    <div className="col-lg-4 col-md-3 col-3">
                        {val.price}
                    </div>
                    <div className="col-lg-3 col-md-3 col-3">
                        {val.payment_type}
                    </div>
                    <div className="col-lg-1 col-md-3 col-2">
                       <div className="viewReceiptLink" onClick={() => toggle(val)}>View</div>
                    </div>
                    </>
               ))} 
                
            </div>
            <div>
            <br />
            </div>
            {showDetails ? 
            <div id="receiptDetails">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-6">
                    <img className="receipt-logo" src="https://filer.fabel.no/web-images/uploads/2019/01/fabel-logo-RGB.png" alt="" />
                </div>
                <div className="col-lg-6 col-md-6 col-6 text-align-right">
                    <h3 className="title-receipt">Kvittering</h3>
                </div>
            </div>
            <div className="mobile-only">
            <br />
            </div>
            
            
            <div className="row">
                <div className="col-lg-6 col-md-6 col-7">
                    <div className="text-bold ">Fabel Lyd AS</div>
                    Sommerrogata 17, 0255 Oslo
                    <br />
                    Org.br: 945 872 357
                </div>
                <div className="col-lg-6 col-md-6 col-5 text-align-right">
                    Betalt
                    <br />
                    No. {receiptDetails.receipt_number}
                </div>
            </div>
            <div className="gap">
            <br />
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-8 ">
                    <div className="text-bold ">{currentUser.firstname} {currentUser.lastname}</div>
                    {currentUser.email}
                </div>
                <div className="col-lg-12 col-md-12 col-4 mobile-only">
                    <div className="text-bold ">Dato</div>
                   {receiptDetails.payment_date}
                </div>
            </div>
            <div className="gap">
            <br />
            </div>
            <div className="row mobile-only">
                <div className="col-lg-2 col-md-3 col-12 text-bold mobile-only">
                    Periode
                </div>
            </div>
            
            <div>
            <br />
            </div>
            <div className="row">
                <div className="col-lg-2 col-md-3 col-3 text-bold desktop-only">
                    Betalingsdato
                </div>
                <div className="col-lg-8 col-md-3 col-8 text-bold">
                    Beskrivelse
                </div>
                
                <div className="col-lg-2 col-md-3 col-4 text-bold text-align-right" >
                    Pris
                </div>
            </div>
            <div className="row">
                <div className="col-lg-2 col-md-3 col-3 desktop-only" >
                   {receiptDetails.payment_date}
                </div>
                <div className="col-lg-8 col-md-3 col-8">
                    Fabel Fmailie abonnement Stromming av lydboker
                </div>
                
                <div className="col-lg-2 col-md-3 col-4 text-align-right">
                     {receiptDetails.price} kr
                </div>
            </div>
            <div className="row">
            <hr />
            </div>
            <div>
            <br />
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-6 text-bold">
                MVA
                </div>
                <div  className="col-lg-6 col-md-6 col-6 text-align-right">
                {receiptDetails.vat} kr
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-6 mobile-font text-bold">
                Totalt 
                </div>
                <div className="col-lg-6 col-md-6 col-6 mobile-font text-align-right">
                 {receiptDetails.total} kr
                </div>
            </div>
            <div>
            <br />
            </div>
            <div className="row">
            <hr />
            </div>
            <div className="row">
                <div className="col-lg-2 col-md-6 col-6">
                Betalingsmate: 
                </div>
                <div className="col-lg-10 col-md-6 col-6 text-align-left">
                 {receiptDetails.payment_type}
                </div>
                
            </div>
            <div className="gap">
            <br />
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                fabel.no
                <br />
                <div className=" text-bold">kundeservice</div>
                kontakt@fabel.no
                </div>
            </div>
            </div>
            : ''} 
         </div>
    )
}

export default Receipt
