export const checkExpired = (date) => {
  var today = new Date();
  var expDate = new Date(date);
  if (today > expDate) {
    console.log(today, expDate)
    return true;
  }else{
    return false;
  }
};

export const formatDateString = (string) => {
  if (typeof string != "undefined" && string != null) {
    const day = string.substring(8, 10);
    const month = string.substring(5, 7);
    const year = string.substring(0, 4);
    return day + "." + month + "." + year;
  }
  return null;
};
export const dateUntilCredit = (subscription) => {
  var today = new Date().getTime();
  if(subscription.next_billing_date == undefined){
    return "Invoice"
  }else{
    var billing = subscription.next_billing_date.split(/[- :]/);
    var billing_date = new Date(
      billing[0],
      billing[1] - 1,
      billing[2],
      billing[3],
      billing[4],
      billing[5]
    );
    var result = Math.ceil(billing_date - today) / (1000 * 60 * 60 * 24);
    return result.toFixed();
  }

};

