//import React, { useState } from 'react'
//import UserService from '../services/UserService'
//import { useNavigate, useParams, useSearchParams    } from "react-router-dom";

const YesToNewsletter = () => {
    //const [searchParams] = useSearchParams();
    const email = "wajid@test.com";
    //console.log(email);
    return (
         <div>
            <div>
            {email}
            </div>
         </div>
    )
}

export default YesToNewsletter
